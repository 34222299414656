import PropTypes from 'prop-types'
import {Map} from 'immutable'
import {useCallback} from 'react'

import routerUtils from '../../../../../../lib/routerUtils'
import contactIcon from '../../../../../../shared_assets/contact.png'
import {Text} from '../../../blocks/Texts'
import {contactLabel} from '../../../../../../lib/contactTools'

import './contact.scss'

const Contact = ({contact, logEvent}) => {
  const onClick = useCallback(() => {
    logEvent('clicked_search_result', {
      type: 'contact'
    })
    routerUtils.push(
      `/cm/address-book/${contact.get('id')}?showAdditionalFields=true`
    )
  }, [contact.get('id')])

  return (
    <div className='flex-container result border-bottom contact-result' onClick={onClick}>
      <img src={contactIcon} alt='contact-icon' className='profile-circle' />
      <div className='result-content'>
        <Text className='bold-text'>
          {contactLabel(contact)}
        </Text>
        <Text>{contact.getIn(['emails', 0])}</Text>
      </div>
    </div>
  )
}

Contact.propTypes = {
  contact: PropTypes.instanceOf(Map),
  logEvent: PropTypes.func
}

export default Contact
