import PropTypes from 'prop-types'
import React from 'react'

import Logo from '../../../../shared_components/Logo'
import MobileHeaderEverplansBrandedCobranding from '../../../../shared_components/everplans_branded_cobranding/MobileHeaderEverplansBrandedCobranding'
import PoweredByHorizontal from '../../../../shared_components/footer/PoweredByHorizontal'
import {hasWhiteLabelCobranding, hasEverplansCobranding} from '../../../../lib/cobrandingTools'

import './navHeader.scss'


const NavHeader = props => (
  <header className='nav-header flex-child'>
    <a href='#/dashboard'>
      {
        hasWhiteLabelCobranding(props.cobranding) ?
          (
            <div className='cobranding-wrapper'>
              <img src={props.cobranding.header_logo} alt='cobranding-logo' className='cobranding-logo' />
              <PoweredByHorizontal {...props.cobranding} className='' id='powered-by-logo' />{/* passing empty class to override footer-specific classes in the component */}
            </div>
          ) :
          <Logo />
      }
    </a>
    {hasEverplansCobranding(props.cobranding) && <MobileHeaderEverplansBrandedCobranding cobranding={props.cobranding} />}
  </header>
)

NavHeader.propTypes = {
  cobranding: PropTypes.object
}

NavHeader.defaultProps = {
  cobranding: {}
}

export default NavHeader
