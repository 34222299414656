var Icon = (props) =>
  (<i className={`fa ${props.className}`} aria-hidden={props.ariaHidden}></i>)

Icon.defaultProps = {
  ariaHidden: true
}

var Circle = (props) =>
(<Icon className={`fa-circle ${props.className}`} />)


export { Icon, Circle }
