// stolen from: https://github.com/gaearon/react-side-effect --BJK

import PropTypes from 'prop-types'

import React from 'react'
import withSideEffect from 'react-side-effect'
import Container from '../../lib/Container'
import StorePrototype from '../StorePrototype'

Container.registerStore('headerFlavor', StorePrototype())

class HeaderFlavor extends React.Component {
  render() {
    if (this.props.children)
      return React.Children.only(this.props.children)
    else
      return null
  }
}

HeaderFlavor.propTypes = {
  flavor: PropTypes.string.isRequired
}

HeaderFlavor.defaultProps = {flavor: 'app'} // default start value

const reducePropsToState = propsList => {
  const innerMostProps = propsList[propsList.length - 1]

  if (innerMostProps)
    return innerMostProps.flavor
}

const handleChange = flavor => {
  if (flavor) {
    Container.getStore('headerFlavor').receiveData({flavor})
    HeaderFlavor.flavor = flavor
  }
}

export default withSideEffect(reducePropsToState, handleChange)(HeaderFlavor)
