/** @function currentUserWrapper
 *
 * @desc
 * Higher-order function that automatically constructs a controller/wrapper class that
 * gets the `currentUser` data from the `SessionStore` and passes the information down to
 * its children. The controller also passes down all other `props` it receives and a
 * `loadingCurrentUser` prop. `currentUserWrapper` also accepts and optional `fetchCurrentUser`
 * prop that when `true` triggers a new fetching of the `currentUser` from the API. The data
 * passed as `currentUser` to the children is not static, as this wrapper also listens for
 * changes in the `SessionStore` and will pass new props as the data changes.
 *
 * @param {Component} Component - The React component that needs access to `currentUser` and
 *   that will be treated as the children of the newly-created controller class.
 * @param {boolean} [fetchCurrentUser=null] - an optional Boolean that when passed as `true` tells
 *   `currentUserWrapper` to not only listen to the `SessionStore` but also fetch the
 *   `currentUser` during `componentDidMount`.
 */

import $ from 'jquery'
import React from 'react'

import SessionApi from '../auth/src/web/SessionApi'
import SessionStore from '../auth/src/stores/SessionStore'

const currentUserWrapper = (Component, fetchCurrentUser) => {
  class CurrentUserWrapper extends React.Component {
    constructor() {
      super()

      const storeState = SessionStore.getState()
      this.state = {
        currentUser: storeState.currentUser,
        preAuthEmail: storeState.preAuthEmail,
        loadingCurrentUser: $.isEmptyObject(SessionStore.getState().currentUser)
      }

      this.onSessionUpdate = this.onSessionUpdate.bind(this)
    }

    componentDidMount() {
      SessionStore.addChangeListener(this.onSessionUpdate)
      if (fetchCurrentUser) SessionApi.fetchCurrentUser()
    }

    onSessionUpdate() {
      const storeState = SessionStore.getState()
      this.setState({
        currentUser: storeState.currentUser,
        loadingCurrentUser: false,
        preAuthEmail: storeState.preAuthEmail
      })
    }

    componentWillUnmount() { SessionStore.removeChangeListener(this.onSessionUpdate) }

    render() { return <Component {...this.props} {...this.state} /> }
  }

  return CurrentUserWrapper
}

export default currentUserWrapper
