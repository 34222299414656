import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import Contact from './views/Contact'

const ContactResults = ({contactResults, logEvent}) => {
  if (contactResults.isEmpty()) {
    return null
  } else {
    return (
      <div className='contact-results'>
        <div className='border-bottom section-header'>CONTACTS</div>
        <div>
          {contactResults.toSeq().map(contact => (
            <Contact
              key={contact.get('id')}
              contact={contact}
              logEvent={logEvent}
            />
          ))}
        </div>
      </div>
    )
  }
}

ContactResults.propTypes = {
  contactResults: PropTypes.instanceOf(List),
  logEvent: PropTypes.func
}

export default ContactResults
