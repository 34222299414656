/** @module userTools
 *
 * @desc
 * Helpers related to `Users`, including condition checking, information
 * gathering, and updating.
 */
import {Map} from 'immutable'

import {cleanResponse} from '../dashboard/src/components/corp_max/redux/apiHelper'
import {findResourceById, findResourceByAttributeId, findRawResourceByAttributeId, compareAsStrings, findRawResourceById} from './plan_data/userData'


/**
 * Sets the value of the `flagToSet` key in the `viewed_flags` for the given `user`
 * to `true` if the `user` argument is present and the `flagToSet` has not already
 * been set to `true` for the `user`.
 *
 * @param {string} flagToSet - The name of the flag to set to `true` in the `viewed_flags`
 *   array for the `user`.
 * @param {Map} user - The `User` for which `flagToSet` is to be set to `true`.
 * @param {function} updateResource - from the `redux-json-api` library passed from a `connected` component used to update the User
 */
export const setViewedFlagV2 = ({flagToSet, user, updateResource, callback = () => {}}) => {
  if (user.get('viewed-flags') && !user.getIn(['viewed-flags', flagToSet])) {
    const resource = {
      type: 'users',
      id: user.get('id'),
      attributes: {
        'viewed-flags': {
          ...user.get('viewed-flags').toJS(),
          [flagToSet]: true
        }
      }
    }

    updateResource(resource).then(callback)
  }
}

/**
 * Returns the first userConfg from the `state.api['user-configs']` since there is always only every one
 *
 * @param {Object} api from global state
 *
 * @returns {Map} userConfig for the current user
 */
export const currentUserConfig = api => cleanResponse(api['user-configs']).first() || Map()

/**
 * Returns the current user from the `state.api.users`
 *
 * @param {Map} userConfig
 * @param {Object} users from global state
 *
 * @returns {Map} current user
 */
export const currentUser = ({userConfig, users}) => findResourceById({resourceList: cleanResponse(users), id: userConfig.get('id')})


/**
 * Returns the current user's profile from the `state.api.profiles`
 *
 * @param {Map} userConfig
 * @param {Object} profiles from global state
 *
 * @returns {Map} current user's profile
 */
export const userProfile = ({userConfig, profiles}) => findResourceByAttributeId({resourceList: cleanResponse(profiles), attribute: 'user-id', id: userConfig.get('id')})

/**
 * Returns the current user's partner from the `state.api.partners`
 *
 * @param {Map} userConfig
 * @param {Object} partners from global state
 *
 * @returns {Map} current user's partner
 */
export const userPartner = ({userConfig, partners}) => findResourceById({resourceList: cleanResponse(partners), id: userConfig.get('partner-id')})


/**
 * Returns the current user's assessment from the `state.api.assessment`
 *
 * @param {Map} userConfig
 * @param {Object} assessments from global state
 *
 * @returns {Map} current user's assessment
 */
export const userAssessment = ({userConfig, assessments}) => findRawResourceByAttributeId({rawResource: assessments, attribute: 'user-id', id: userConfig.get('id')})

/**
 * Finds the owner ownership of the current user based on id and type
 *
 * @param {Map} userConfg
 * @param {List} ownerships
 *
 *
 * @returns {Map} ownerOwnership of the current user
 */
export const userOwnerOwnership = ({userConfig, ownerships}) => (
  ownerships.find(owner => owner.get('ownable-type') === 'User' && compareAsStrings(owner.get('ownable-id'), userConfig.get('id')))
)

/**
 * Finds the owner ownership of the current user based on id and type
 *
 * @param {Map} userConfg
 * @param {List} ownerships
 *
 *
 * @returns {Map} ownerOwnership of the current user
 */
export const userGapFillerContentDownload = ({profile, contentDownloads}) => (
  contentDownloads
    .filter(
      contentDownload => (
        contentDownload.get('content-download-type') === 'gap-filler' &&
        contentDownload.get('state') === profile.get('state')
      )
    )
)

/**
 * Finds the owner ownership of the other user for a household
 *
 * @param {Map} userConfig
 * @param {List} ownerships
 *
 *
 * @returns {Map} ownerOwnership of the second household member
 */
export const otherUserOwnerOwnership = ({userConfig, ownerships}) => (
  ownerships.find(
    ownership => !ownership.equals(userOwnerOwnership({userConfig, ownerships}))
  )
)

export const userFirmOrganization = ({api}) => findRawResourceById({
  rawResource: api.organizations,
  id: findRawResourceById({
    rawResource: api.firms,
    id: currentUserConfig(api).get('firm-id')}).get('organization-id')
})

/**
 * @param {Map} ownEverplan
 * @param {string} context
 *
 *
 * @returns {string} actor of an action being done
 */
export const currentActor = ({ownEverplan, context}) => {
  if (ownEverplan)
    return 'planholder'

  return context === 'pro_client_dashboard' ? 'pro' : 'deputy'
}

/**
 * Check if user needs to respond to required actions
 * @param {Map} userConfig
 * @returns {boolean}
 */
export const needsRequiredActionCheck = userConfig => !(userConfig.get('admin') || userConfig.get('account-manager'))
