import PropTypes from 'prop-types'
import classnames from 'classnames'

import CobrandingStyles from '../../shared_components/CobrandingStyles'
import {hasWhiteLabelCobranding} from '../../../../lib/cobrandingTools'

import './navWrapper.scss'


const NavWrapper = props => (
  <div className={classnames('nav-wrapper flex-child', props.displayBanner, {'ep-preview': props.isPreviewPage})}>
    <div className='nav-inner-wrapper flex-container'>
      {props.children}
    </div>
    {hasWhiteLabelCobranding(props.cobranding) && <CobrandingStyles cobranding={props.cobranding} />} {/* Render after nav wrapper in order to display cobranding styles in IE 10+ correctly --KW */}
  </div>
)

NavWrapper.propTypes = {
  cobranding: PropTypes.object,
  displayBanner: PropTypes.string,
  isPreviewPage: PropTypes.bool
}

NavWrapper.defaultProps = {
  cobranding: {}
}

export default NavWrapper
