import $ from 'jquery'
import PropTypes from 'prop-types'
import React from 'react'

import ButtonGroup from './core/buttons/ButtonGroup'
import * as Buttons from '../dashboard/src/components/blocks/Buttons'
import Modals from './core/modals/Modals'
import {TextGutterMedium} from '../dashboard/src/components/blocks/Texts'

import AppDispatcher from '../lib/ep-dispatcher'
import {railsUrl} from '../lib/urlTools'
import routerUtils from '../lib/routerUtils'

import './timeoutModal.scss'

export default class TimeoutModal extends React.Component {
  constructor() {
    super()

    this.state = {
      lastActivityAt: Date.now(),
      modalSecondsLeft: -1,
      showModal: false
    }

    this.constructCountdownString = this.constructCountdownString.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleMouseClick = this.handleMouseClick.bind(this)
    this.inactivityChecker = this.inactivityChecker.bind(this)
    this.logOut = this.logOut.bind(this)
    this.modalCountdown = this.modalCountdown.bind(this)
    this.showTimeoutModal = this.showTimeoutModal.bind(this)
    this.stayLoggedIn = this.stayLoggedIn.bind(this)
    this.updateLastActivityAt = this.updateLastActivityAt.bind(this)

    this.dispatcherToken = AppDispatcher.register(this.updateLastActivityAt)
  }

  componentDidMount() {
    $(document.body).on('click', this.handleMouseClick)
    $(document.body).on('keydown', this.handleKeyPress)
    if (this.props.needsCheck)
      this.startInactivityChecker()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (nextState.showModal !== this.state.showModal || nextState.showModal)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.needsCheck && !this.props.needsCheck)
      this.startInactivityChecker()
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this.dispatcherToken) // Stop trying to set state if not on the DOM. --BLR
    this.stopInactivityChecker()
    $(document.body).off('click', this.handleMouseClick)
    $(document.body).off('keydown', this.handleKeyPress)
  }

  startInactivityChecker() {
    this.checkForInactivity = setInterval(this.inactivityChecker, 1000)
    this.updateLastActivityAt() // Set the timer to zero, effectively.
  }

  stopInactivityChecker() {
    if (this.checkForInactivity)
      clearInterval(this.checkForInactivity)
  }

  inactivityChecker() {
    if (Date.now() - this.state.lastActivityAt >= 720000) // 12 minutes * 60 seconds/minute * 1000 ms/second = 720,000 ms
      this.showTimeoutModal()
  }

  handleKeyPress() {
    if (this.checkForInactivity)
      this.updateLastActivityAt()
  }

  handleMouseClick() {
    if (this.checkForInactivity)
      this.updateLastActivityAt()
  }

  updateLastActivityAt() { this.setState({lastActivityAt: Date.now()}) }

  modalCountdown() {
    this.setState({modalSecondsLeft: this.state.modalSecondsLeft - 1})
    if (this.state.modalSecondsLeft <= 0)
      this.logOut()
  }

  showTimeoutModal() {
    this.stopInactivityChecker()
    this.setState({
      modalSecondsLeft: 180,
      showModal: true
    })
    this.checkModalInactivity = setInterval(this.modalCountdown, 1000)
  }

  logOut() {
    clearInterval(this.checkModalInactivity)
    this.props.signoutCallback()
  }

  stayLoggedIn() {
    clearInterval(this.checkModalInactivity)
    this.setState({
      modalSecondsLeft: -1,
      showModal: false
    })
    this.startInactivityChecker()
  }

  constructCountdownString() {
    // Prevent awkward flicker of an apparent sentence fragment during actual logout.
    if (this.state.modalSecondsLeft < 0) return 'a moment'

    let countdownString = ''
    const minutes = Math.floor(this.state.modalSecondsLeft / 60)
    const seconds = this.state.modalSecondsLeft % 60

    switch (minutes) {
      case 0:
        break
      case 1:
        countdownString += '1 minute'
        break
      default:
        countdownString += `${minutes} minutes`
        break
    }

    if (minutes > 0 && seconds > 0)
      countdownString += ' and '

    switch (seconds) {
      case 0:
        break
      case 1:
        countdownString += '1 second'
        break
      default:
        countdownString += `${seconds} seconds`
        break
    }

    return countdownString
  }

  render() {
    return (
      <Modals.PopUpModal className='timeout-modal' showCloser={false} showModal={this.state.showModal}>
        <h2>Are you still using Everplans?</h2>
        <TextGutterMedium>
          We have not detected any activity from you recently. To keep your account secure,
          we will automatically log you out in {this.constructCountdownString()}.
        </TextGutterMedium>
        <ButtonGroup>
          <Buttons.Cancel onClick={this.logOut}>Log out</Buttons.Cancel>
          <Buttons.Accept onClick={this.stayLoggedIn}>Stay logged in</Buttons.Accept>
        </ButtonGroup>
      </Modals.PopUpModal>
    )
  }
}

TimeoutModal.propTypes = {
  needsCheck: PropTypes.bool,
  signoutLink: PropTypes.func
}

TimeoutModal.defaultProps = {
  signoutCallback: () => { routerUtils.setLocation(railsUrl('sign_out?msg=timeout')) }
}
