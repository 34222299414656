import classnames from 'classnames'

import './unorderedList.scss'


const UnorderedList = props => (
  <ul className={classnames('unordered-list', props.className)}>
    {props.children.map(child => <li className='item' key={child.toString()}>{child}</li>)}
  </ul>
)

UnorderedList.defaultProps = {
  children: [],
  className: ''
}

export default UnorderedList
