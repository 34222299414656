import React from 'react'

import Modal from './Modal'

import './responsiveModal.scss'


export default class ResponsiveModal extends React.Component {
  render() {
    return (
      <Modal
        modalType='responsive-modal-content' // Allow props to override default if modalType is passed
        {...this.props}
      />
    )
  }
}
