import $ from 'jquery'
import PropTypes from 'prop-types'
import {Component} from 'react'

import closeButton from '../shared_assets/close-modal.svg'
import responsiveCloseX from '../shared_assets/close-modal-x.svg'

import './modal.scss'


export default class Modal extends Component {
  componentDidMount() {
    if (this.props.visible)
      this.addModalOpenClassToBody()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.visible !== newProps.visible)
      this.updateBody(newProps.visible)
  }

  componentWillUnmount() {
    if (this.props.visible)
      this.updateBody(false)
  }

  addModalOpenClassToBody() { $('body').addClass('modal-open') }

  closerDecorator() {
    if (this.props.closer) {
      return (
        <a onClick={this.props.closer} title='Close' className='modal-close'>
          <div className='unresponsive-modal-close'>
            <img alt='Close modal' src={closeButton} />
          </div>
          <div className='responsive-modal-close'>
            <img alt='Close modal' src={responsiveCloseX} />
          </div>
        </a>
      )
    } else {
      return <div />
    }
  }

  removeModalOpenClassFromBody() { $('body').removeClass('modal-open') }

  updateBody(visible) {
    if (visible)
      this.addModalOpenClassToBody()
    else
      this.removeModalOpenClassFromBody()
  }

  render() {
    if (this.props.visible) {
      return (
        <div className='modal-overlay'>
          <div className='modal-box'>
            <div className={this.props.modalType ? this.props.modalType : 'modal-content'}>
              {this.closerDecorator()}
              {this.props.children}
            </div>
          </div>
        </div>
      )
    } else {
      return (<div />)
    }
  }
}

Modal.propTypes = {
  closer: PropTypes.func,
  modalType: PropTypes.string,
  visible: PropTypes.bool
}
