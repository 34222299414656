import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import Item from './views/Item'

const ItemResults = ({categories, kits, itemResults, logEvent}) => {
  if (itemResults.isEmpty()) {
    return null
  } else {
    return (
      <div className='item-results'>
        <div className='border-bottom section-header'>ADD NEW</div>
        <div>
          {itemResults.toSeq().map(item => (
            <Item
              categories={categories}
              item={item}
              key={item.get('id')}
              kits={kits}
              logEvent={logEvent}
            />
          ))}
        </div>
      </div>
    )
  }
}

ItemResults.propTypes = {
  categories: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  itemResults: PropTypes.instanceOf(List)
}


export default ItemResults
