import PropTypes from 'prop-types'

import Closer from '../../../../../../shared_components/core/closer/Closer'

import magnifyingGlass from '../../../../../../shared_assets/magnifyingGlass.svg'

import './searchIcon.scss'

const SearchIcon = ({showResults, resetSearch}) => (
  <div className='search-icon'>
    {
      showResults ?
        <Closer onClick={resetSearch} fill='#A6A6A6' height='12px' width='12px' /> :
        <img className='magnifying-glass' alt='search' src={magnifyingGlass} />
    }
  </div>
)

SearchIcon.propTypes = {
  showResults: PropTypes.bool,
  resetSearch: PropTypes.func
}

export default SearchIcon
