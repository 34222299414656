/** @function bindResources
 *
 * @desc
 * Higher-order function that constructs a wrapper class that automatically fetches resources
 * from our API and passes them down as props to its children. This component is highly
 * opinionated and makes a lot of assumptions about action, resource, store, and prop names.
 *
 *
 * This component only works for these scenarios:
 *   1. A GET request where no ID is needed (i.e., an index). In this case no URL parameter is
 *     used to construct the API endpoint URL;
 *   2. A SHOW request where no ID is needed (e.g., a show where the returned data is based on who made the request); or,
 *   3. A SHOW request where the ID that is needed to construct the URL is a URL param that is accessible to
 *     `bindResources` via the `params` prop passed by ReactRouter.
 *
 * We are inconsistent in our naming of this function: we often alias this function via `import` statements as
 *   `bindResource`, `bindToStores`, and `BindToStores`. These all do the same thing.
 *
 * @param {Component} ComponentToBeWrapped - The React component that needs access to the chosen
 *   resources and that will be treated as the children of the newly-created controller
 *   class.
 * @param {Array<Object>} resources - An array of configuration objects describing what data
 *   should be fetched from the server. Each resource
 * @param {string} [resources[].id] - A String representing the URL parameter to use to
 *   fetch the resource. This is only necessary if the ID to be used does not match the
 *   assumptions listed in the description above.
 * @param {string} resources[].name - The name of the resource to be fetched.
 * @param {string} resources[].type - The type of the resource to be fetched. Must be one
 *   of: `item`, `itemAllParams`, `itemNoId`, and `list`.
 */

import {Component} from 'react'
import PropTypes from 'prop-types'
import invariant from 'invariant'

import {capitalize} from '../lib/tools'
import Dev from '../dev_only'
import Container from '../lib/Container'
import storePrototype from './StorePrototype'

export default function bindResources(ComponentToBeWrapped, resources, onBoundUpdate = null) {
  const stores = {}
  const storeNames = () => Object.getOwnPropertyNames(stores)

  const resourceConfigs = (Array.isArray(resources) ? resources : [resources]) // Handle old, one-resource pattern too.

  let bootObjects = [] // Objects containing all necessary information for firing the bootAction of each resource
    // and the tracking of the loading state of each resource.
    // Example of the bootObject for an 'item' resource:
       // {bootAction: 'fetchClient', actionClass: 'ClientActions', resourceType: 'item', resourceId: '12'}

  // Construct a bootObject for every resource:
  resourceConfigs.forEach(resourceConfig => {
    const resourceName = resourceConfig.name

    // Get the action class--previously registered with the Container--used to fetch the resource data:
    const actionClass = Container.getAction(resourceName)

    // Get or create the store used to hold the resource data:
    let resourceStore = Container.getStore(resourceName)
    if (!resourceStore)
      resourceStore = Container.registerStore(resourceName, storePrototype(actionClass.Types[`GOT_${resourceName.toUpperCase()}`]))

    // Add the store to the list of store the BoundComponent will listen to:
    stores[resourceName] = resourceStore

    bootObjects.push({
      actionClass,
      bootAction: `fetch${capitalize(resourceName)}`,
      loadingName: `loading${capitalize(resourceName)}`,
      resourceId: (resourceConfig.id ? resourceConfig.id : `${capitalize(resourceName, false)}Id`),
      resourceType: resourceConfig.type
    })
  })


  /**
   * @class BoundComponent
   *
   * @desc
   * Factory-generated component that fetches a given set of resources and passes it to
   * its children as props.
   */
  class BoundComponent extends Component {
    constructor() {
      super()

      this.itemLoaders = []
      this.state = {
        loading: true,
        processing: false
      }
    }

    /**
     * Adds resource-specific listener methods and fires all necessary API requests to GET each resource.
     */
    UNSAFE_componentWillMount() {
      // Create a listener for each resource store:
      storeNames().forEach(store => {
        const onChangeHandlerName = `handle${store}Change`
        this[onChangeHandlerName] = this.onChangeFactory(store)
        stores[store].addChangeListener(this[onChangeHandlerName])
      })

      // Fire the bootAction to fetch each resource:
      let loadingStates = {}

      bootObjects.forEach(bootObject => {
        loadingStates[bootObject.loadingName] = true

        // Need to hoist these values when called again from UNSAFE_componentWillReceiveProps (BJK):
        const resourceId = bootObject.resourceId
        const loadingName = bootObject.loadingName
        const bootAction = bootObject.bootAction

        /* eslint-disable indent */ // Fix ESLint complaining about our preferred switch syntax.
        // Choose which ID--if any--is passed to the GET request to identify the resource:
        switch (bootObject.resourceType) {
          case 'item': // One specific item that MUST have an ID included in the GET request.
            const checkId = nextProps => { // Set up a function that reloads the resource if the ID in the params changes
              if (nextProps && nextProps.params[resourceId] === this.props.params[resourceId])
                return

              loadingStates[loadingName] = true
              const idParam = nextProps ? nextProps.params[resourceId] : this.props.params[resourceId]

              if (!idParam) { // Skip bootAction for items requiring ID if no ID URL param is available.
                loadingStates[loadingName] = false
                return
              }
              bootObject.actionClass[bootAction](idParam)

              // Update the previous loading state if it is present:
              if (this.state && typeof this.state[Object.keys(loadingStates)[0]] !== 'undefined')
                this.setState(loadingStates)
            }

            checkId()
            this.itemLoaders.push(checkId)
            break
          case 'itemAllParams': // One specific item with a more-complex API endpoint URL as constructed in the action.
            bootObject.actionClass[bootObject.bootAction](this.props.params)
            break
          case 'itemNoId': // Single item that requires no ID (e.g., global config). Separate from list only to make it clear that it is only one item.
          case 'list': // List of items accessible at a single endpoint that does not require any resource ID.
            bootObject.actionClass[bootObject.bootAction]()
            break
          default: // Do not allow any resources of any other type to be requested--mainly a dev sanity check.
            invariant(bootObject.resourceType, '`bootObject.resourceType` is unknown or undefined.')
        }
        /* eslint-enable indent */
      }, this)
      this.setState(loadingStates)
    }

    /**
     * Reloads any resource if the associated URL ID changes
     */
    UNSAFE_componentWillReceiveProps(nextProps) { this.itemLoaders.forEach(checkId => checkId(nextProps)) }

    /**
     * Removes all store listeners when component is unmounting.
     */
    componentWillUnmount() {
      storeNames().forEach(store => stores[store].removeChangeListener(this[`handle${store}Change`]))
    }

    /**
     * DO NOT USE THIS: this method is junk and deprecated. It's only still present so we don't break brittle legacy usage of it.
     */
    goToThere(targetObject) { // Push new router path if requested to do so in onBoundUpdate.
      Dev.tools.log('WARNING: goToThere is fully deprecated! Do not add code using that pattern--let your component decided what to do when it receives the data as props.')
      this.context.router.push(targetObject)
    }

    /**
     * Constructs a change listener method for a given `store`.
     */
    onChangeFactory(store) { // Create a custom listener identifier so loading states can be tracked independently for each resource.
      const myStore = store
      return () => {
        let newState = {loading: false} // Backwards-compatability for single-resource bindings.
        let newStoreState = stores[myStore].getState()
        newState[myStore] = newStoreState
        newState[`loading${capitalize(store)}`] = false

        // NOTE: it is the responsibility of the onBoundUpdate function to set the state if onBoundUpdate is included.
        if (onBoundUpdate && typeof onBoundUpdate === 'function') {
          Dev.tools.log('WARNING: onBoundUpdate is fully deprecated! Do not add code using that pattern--let your component decided what to do when it receives the data as props.')
          onBoundUpdate.apply(this, [newStoreState])
        } else {
          this.setState(newState)
        }
      }
    }

    render() {
      return (
        <ComponentToBeWrapped
          {...this.props}
          {...this.state}
          processing={this.props.processing || this.state.processing} // Prevent stomping on props passed from parents. --BLR
        />
      )
    }
  }

  BoundComponent.contextTypes = {router: PropTypes.object}

  BoundComponent.displayName = `Bound${ComponentToBeWrapped.name}`

  return BoundComponent
}
