import PropTypes from 'prop-types'
import {List} from 'immutable'

import Response from './views/Response'
import {allResponseGroups} from '../../../../../lib/responsesHelper'

const ResponseResults = ({responseResults, ...otherProps}) => {
  if (responseResults.isEmpty()) {
    return null
  } else {
    return (
      <div className='response-results'>
        <div className='section-header border-bottom'>IN YOUR EVERPLAN</div>
        <div>
          {allResponseGroups(responseResults)
            .valueSeq()
            .map(responseResultGroup => (
              <Response
                {...otherProps}
                responseResultGroup={responseResultGroup}
                key={responseResultGroup.getIn([0, 'id'])}
              />
            ))}
        </div>
      </div>
    )
  }
}


ResponseResults.propTypes = {
  responseResults: PropTypes.instanceOf(List)
}

export default ResponseResults
