import {Map, fromJS, List} from 'immutable'

const searchMappings = fromJS({
  vehicle: ['car', 'auto', 'autos'],
  attorney: ['lawyer'],
  employment: ['work', 'occupation'],
  home: ['house', 'residence', 'real estate', 'apartment'],
  will: ['last will'],
  medication: ['medicine'],
  'advance directive': ['living will', 'personal directive'],
  financial: ['finance', 'money', 'assets', 'portfolio'],
  health: ['medical'],
  upload: ['attachment', 'file'],
  children: ['kids', 'child', 'kid', 'son', 'daughter'],
  '401(k)': ['401k'],
  '403(b)': ['403b']
})

// Remap search mapping to be faster to look up.
const reducer = (collection, values, key) => {
  let newCollection = collection
  values.map(value => { newCollection = newCollection.set(value, key) })
  return newCollection
}

const searchDictionary = searchMappings.reduce(reducer, Map())

export const lookUpSearchDictionary = text => {
  const otherMatchingWord = searchDictionary.get(text.toLowerCase())
  return otherMatchingWord ? List([otherMatchingWord, text]) : List([text])
}
