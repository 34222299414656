const SecurityIcon = () => (
  <svg className='security-icon' width='23px' height='26px' viewBox='0 0 23 26' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <title>Group</title>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Group' transform='translate(1.000000, 1.000000)' stroke='#FFFFFF' strokeWidth='2'>
        <path d='M20.25,8 C20.6642136,8 21,8.34111638 21,8.76190476 L21,23.2380952 C21,23.6588836 20.6642136,24 20.25,24 L0.75,24 C0.335786438,24 0,23.6588836 0,23.2380952 L0,8.76190476 C0,8.34111638 0.335786438,8 0.75,8 L20.25,8 Z M10.5566898,0 C15.1855633,0 17.5,2.66666667 17.5,8 L3.5,8 C3.57558642,2.66666667 5.92781636,0 10.5566898,0 Z' id='Shape' />
        <circle id='Oval' cx='10.5' cy='14.5' r='2' />
        <line x1='10.5' y1='17' x2='10.5' y2='19' id='Line' strokeLinecap='round' />
      </g>
    </g>
  </svg>
)

export default SecurityIcon
