import PropTypes from 'prop-types'

import breakpoints from '../../../dashboard/styles/abstracts/breakpoints.module.scss'
import colors from '../../../dashboard/styles/abstracts/colors.module.scss'
import hexToRGBA from '../../../lib/hexToRGBA'

const CobrandingStyles = props => {
  if (props.cobranding.enabled) {
    const background = props.cobranding.background_color
    const foreground = props.cobranding.foreground_color
    const brighterForegroundBorder = hexToRGBA(foreground, 0.2)
    const brighterForegroundText = hexToRGBA(foreground, 0.8)

    const dangerousStyles = {
      __html: `
        .legacy-my-everplan-links.nav-mobile-action-links,.my-everplan-links.nav-mobile-action-links { background-color: ${background}; }

        /* Navigation Wrapper ////////// */
        .nav-wrapper .nav-inner-wrapper { background-color: ${background}; }

        @media (min-width: ${breakpoints.tabletLg}) {

          .nav-wrapper .nav-inner-wrapper { top: 0; }
        }

        /* Nav Header & User Menu ////////// */
        header.nav-header {
          background-color: ${background};
          flex: 3;
          padding: 20px;
        }

        .user-menu.user-menu-mobile {
          background-color: ${background};
          padding: 3% 15px 3% 0;
        }

        .user-menu.user-menu-mobile .down-arrow-icon { fill: ${colors.headerText}; }

        .user-menu.user-menu-mobile .down-arrow-icon path { stroke: ${colors.headerText}; }

        .help-center-link.help-center-link-mobile {
          background-color: ${background};
          color: ${foreground};
          padding: 3% 0;
        }

        .help-center-link .help-link-text,
        .help-center-link.help-center-link-mobile .help-link-text,
        .security-info-launcher .security-info-text { 
          color: ${colors.bodyText}; 
        }

        .help-center-link .help-icon { fill: ${colors.headerText}; }

        .security-info-link .security-info-launcher.security-info-launcher-mobile .security-icon { 
          filter: brightness(0) saturate(100%) invert(11%) sepia(0%) saturate(0%) hue-rotate(223deg) brightness(102%) contrast(91%);
        }

        /* Navigation ////////// */
        nav.nav { border-top: 1px solid ${brighterForegroundBorder}; }

        nav.nav a:hover { color: ${brighterForegroundText}; }

        nav.nav .active-nav-group a.nav-group-name,
        nav.nav .active-nav-group .nav-group-name { color: ${foreground}; }

        nav.nav .nav-groups {
          background-color: ${background};
          border-bottom: 1px solid ${brighterForegroundBorder};
        }

        nav.nav a,
        nav.nav .nav-group-name,
        nav.nav .active a,
        .nav-categories .nav-category.active a { color: ${foreground}; }

        nav.nav .nav-preview.show-dropdown-nav,
        nav.nav .nav-preview-mobile.show-dropdown-nav,
        nav.nav .secure-sharing-mobile.show-dropdown-nav,
        nav.nav .secure-sharing-mobile.show-dropdown-nav .nav-deputy-link a,
        .nav-categories.nav-categories-mobile.show-dropdown-nav {
          background-color: ${background};
          color: ${foreground};
        }

        .nav-categories.nav-categories-mobile.show-dropdown-nav a:hover,
        .nav-categories.nav-categories-mobile.show-dropdown-nav .nav-document a:hover { color: ${brighterForegroundText}; }

        @media (min-width: ${breakpoints.mobileMd}) {
          .security-info-link .security-info-launcher.security-info-launcher-mobile .security-icon { 
            margin-right: 0;
          }

          .user-menu.user-menu-mobile {
            padding: 3% 20px 3% 0;
          }
        }

        @media (min-width: ${breakpoints.tabletSm}) {

          header.nav-header { flex: 5; }

          nav.nav .nav-groups .nav-group:nth-child(2) {
            border-left: 1px solid ${brighterForegroundBorder};
            border-right: 1px solid ${brighterForegroundBorder};
          }
        }

        @media (min-width: ${breakpoints.desktopMd}) {

          .nav-wrapper .nav-inner-wrapper { border-right: 1px solid ${brighterForegroundBorder}; }

          header.nav-header {
            display: block;
            flex: 1;
          }

          nav.nav .nav-group {
            border-bottom: 1px solid ${brighterForegroundBorder};
            border-right: none;
          }

          nav.nav .nav-groups .nav-group:nth-child(2) {
            border-left: none;
            border-right: none;
          }

          nav.nav .nav-groups { border-bottom: 0; }

          main.main { box-shadow: none; }
        }

        /* Nav Categories ////////// */
        .nav-categories .nav-category a,
        .nav-categories.show-dropdown-nav a,
        .nav-categories .nav-document.active a,
        .nav-categories.nav-categories-mobile.show-dropdown-nav a,
        .nav-categories.nav-categories-mobile.show-dropdown-nav .nav-document.active a,
        .nav-categories.nav-categories-mobile.show-dropdown-nav.cm-layout .nav-category a,
        .nav-categories.nav-categories-mobile.show-dropdown-nav.cm-layout .nav-category.active a { color: ${foreground}; }

        @media (min-width: ${breakpoints.tabletLg}) {

          .nav-categories.show-dropdown-nav { background-color: ${background}; }

          .nav-categories.show-dropdown-nav .nav-category a,
          .nav-categories.show-dropdown-nav .nav-document.active a { color: ${foreground}; }

          .nav-categories.show-dropdown-nav .nav-category a:hover { color: ${brighterForegroundText}; }

          .nav-categories .nav-document { border-left: 1px solid ${brighterForegroundBorder}; }
        }

        /* Navigation Active/Hover Indicator ////////// */
        nav.nav .active a { color: ${brighterForegroundText}; }

        /* Header Bar ////////// */
        .header-bar { z-index: 1; }

        @media (min-width: ${breakpoints.tabletLg}) {

          .header-bar { background-color: ${colors.boxBackground}; }
        }

        /* Help Center Button ////////// */
        @media (min-width: ${breakpoints.tabletLg}) {

          .help-center-link {
            background-color: ${colors.boxBackground};
            border-right-color: ${colors.boxBorder};
          }
        }

        /* User Menu Button ////////// */
        @media (min-width: ${breakpoints.tabletLg}) {

          .user-menu .current-user-name { color: ${colors.bodyText}; }

          .user-menu .down-arrow-icon { fill: ${colors.bodyText}; }

          .user-menu .down-arrow-icon path { stroke: ${colors.bodyText}; }
        }
      `
    }

    return <style dangerouslySetInnerHTML={dangerousStyles} />
  } else {
    return <noscript />
  }
}

CobrandingStyles.propTypes = {
  cobranding: PropTypes.object
}

export default CobrandingStyles
