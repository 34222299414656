import PropTypes from 'prop-types'
import React from 'react'

import './menuButton.scss'


const MenuButton = props => (
  <button className='menu-button'>
    {props.currentUser.first_name.charAt(0)}
  </button>
)

MenuButton.defaultProps = {
  currentUser: {
    first_name: ''
  }
}

MenuButton.propTypes = {
  currentUser: PropTypes.object
}

export default MenuButton

