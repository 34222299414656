import {gql} from '@apollo/client'
import {ADDRESS_FRAGMENT, CONTACT_FRAGMENT, OWNER_OWNERSHIP_FRAGMENT} from './userData'

const ELEMENT_FRAGMENT = gql`
  fragment ElementFragment on Element {
    id
    name
    conditional
    ordinality
    placeholder
    selectType
    type: elementType
    addMore
    maskText
    listMapping
    mappings {
      conditionalKitId
      value
    }
  }
`

const RESPONSE_VALUE_FRAGMENT = gql`
${ADDRESS_FRAGMENT}
${CONTACT_FRAGMENT}
fragment ResponseValueFragment on Value {
  ...on AddressValue {
    data {...AddressFragment}
  }

  ...on ContactValue {
    data {...ContactFragment}
  }

  ...on StringValue {
    data
  }

  ...on StringArrayValue {
    data
  }

  ...on FileArrayValue {
    data {
      id
      name
    }
  }

  ...on DateValue {
    data {
      mask
      date
    }
  }
}
`

const RESPONSE_FRAGMENT = gql`
  ${RESPONSE_VALUE_FRAGMENT}
  fragment ResponseFragment on Response {
    id
    elementId
    elementType
    maskText
    everplanId
    ownershipIds
    groupId
    value {...ResponseValueFragment}
    createdAt
    updatedAt
  }
`

const SECOND_GROUP_ID_RESPONSES_FRAGMENT = gql`
  ${RESPONSE_FRAGMENT}
  fragment SecondGroupIdResponsesFragment on SecondGroupIdResponses {
    id
    secondGroupId
    responses {...ResponseFragment}
    lastUpdatedAt
    hasAttachment
  }
`

const RESPONSE_GROUP_FRAGMENT = gql`
  ${SECOND_GROUP_ID_RESPONSES_FRAGMENT}
  ${RESPONSE_FRAGMENT}
  ${OWNER_OWNERSHIP_FRAGMENT}
  fragment ResponseGroupFragment on ResponseGroup {
    id
    keyField
    hasAttachment
    lastUpdatedAt
    owners {...OwnerOwnershipFragment}
    topElementsWithResponses {
      id
      elementId
      elementType
      name
      addMore
      responsesGroupedBySecondGroupId {...SecondGroupIdResponsesFragment}
    }
  }
`

export const ITEM_RESPONSE_FRAGMENT = gql`
  ${ELEMENT_FRAGMENT}
  ${RESPONSE_GROUP_FRAGMENT}
  ${RESPONSE_FRAGMENT}
  ${OWNER_OWNERSHIP_FRAGMENT}
  fragment ItemResponseFragment on ItemResponse {
    id
    itemId
    everplanId
    isHousehold
    name
    slug
    header
    importance
    importable
    prompt
    categoryId
    responses {...ResponseFragment}
    createdAt
    guidanceText
    kitId
    sortOrder
    kitElementsMap {
      id
      kitElements {...ElementFragment}
    }
    responseGroups {...ResponseGroupFragment}
    topKits {
      id
      name
      addMore
      parentElementId
    }
    owners {...OwnerOwnershipFragment}
  }
`

