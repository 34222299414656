// TODO:
// Need to create a SVG Icon component to handle multiple icons --KW

const DownArrowIcon = () => (
  <svg className='down-arrow-icon' version="1.1" xmlns="http://www.w3.org/2000/svg" width="54" height="32" viewBox="0 0 54 32">
    <title>EP-DOWNARROW</title>
    <path d="M26.702 31.272c-0.995 0-1.948-0.407-2.633-1.124l-22.839-23.748c-1.391-1.456-1.349-3.767 0.107-5.169 1.456-1.391 3.767-1.349 5.159 0.107l20.206 21.009 20.206-21.009c1.402-1.456 3.714-1.498 5.169-0.096s1.498 3.714 0.096 5.169l-22.839 23.738c-0.685 0.717-1.637 1.124-2.633 1.124z"></path>
  </svg>
)

export default DownArrowIcon
