import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { withRouter } from 'react-router'
import routerUtils from '../../../../lib/routerUtils'

import './dropdownRowBase.scss'


export class DropdownRowBase extends React.Component {
  constructor() {
    super()

    this.goToDestination = this.goToDestination.bind(this)
    this.linkClassnames = this.linkClassnames.bind(this)
  }
  linkClassnames() {
    return classnames(
      'dropdown-row-base',
      this.props.className,
      {active: this.props.router.isActive(`/${this.props.path}`)}
    )
  }
  goToDestination() {
    if (this.props.path)
      routerUtils.push(this.props.path)
  }

  render() {
    return (
      <li className={this.linkClassnames()} onClick={this.goToDestination}>
        {this.props.children}
      </li>
    )
  }
}

export default withRouter(DropdownRowBase)

DropdownRowBase.defaultProps = {
  router: {
    isActive() { return }
  }
}

DropdownRowBase.propTypes = {
  path: PropTypes.string,
  router: PropTypes.shape({
    isActive: PropTypes.func
  })
}
