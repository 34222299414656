import PropTypes from 'prop-types'
import React from 'react'

import DropdownRowBase from './DropdownRowBase'

const UserInfoRow = props => (
  <DropdownRowBase>
    <strong>{props.currentUser.name}</strong>
    {props.currentUser.email}
  </DropdownRowBase>
)

UserInfoRow.defaultProps = {
  currentUser: {
    email: '',
    name: ''
  }
}

UserInfoRow.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string
  })
}

export default UserInfoRow
