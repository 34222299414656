import './buttonGroup.scss'


const ButtonGroup = ({children}) => (
  <div className='button-group core flex-container'>
    {children}
  </div>
)

export default ButtonGroup
