import PropTypes from 'prop-types'

import DropdownRowBase from './DropdownRowBase'
import {MyAccountManagerDashboard} from '../../../../shared_components/Links'
import proSwitchIcon from '../../../assets/ep-switch.svg'


const AccountManagerDashboardRow = ({currentUser}) => {
  if (currentUser.account_manager) {
    return (
      <DropdownRowBase>
        <MyAccountManagerDashboard>
          <img alt='Go to My Acccount Manager Dashboard' className='dropdown-icon' src={proSwitchIcon} />
          My Account Manager Dashboard
        </MyAccountManagerDashboard>
      </DropdownRowBase>
    )
  } else {
    return <noscript />
  }
}

AccountManagerDashboardRow.defaultProps = {
  currentUser: {
    account_manager: false
  }
}

AccountManagerDashboardRow.propTypes = {
  currentUser: PropTypes.shape({
    account_manager: PropTypes.bool
  })
}

export default AccountManagerDashboardRow
