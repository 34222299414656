import PropTypes from 'prop-types'

import {cobrandingPrefixText} from '../../lib/cobrandingTools'

import './desktopNavEverplansBrandedCobranding.scss'


const DesktopNavEverplansBrandedCobranding = ({cobranding}) => (
  <div className='desktop-nav-everplans-branded-cobranding'>
    {cobranding.cobranding_prefix}
    <img alt={cobrandingPrefixText(cobranding)} src={cobranding.header_logo} />
  </div>
)

DesktopNavEverplansBrandedCobranding.defaultProps = {
  cobranding: {
    cobranding_prefix: '',
    cobrandable: {
      name: ''
    },
    header_logo: ''
  }
}

DesktopNavEverplansBrandedCobranding.propTypes = {
  cobranding: PropTypes.shape({
    cobranding_prefix: PropTypes.string,
    cobrandable: PropTypes.shape({
      name: PropTypes.string
    })
  })
}

export default DesktopNavEverplansBrandedCobranding
