import {fromJS, List, Map} from 'immutable'
import {useCallback, useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SearchIcon from './views/SearchIcon'
import SearchResultsContainer from './SearchResultsContainer'

import Logger from '../../../../../lib/NewLogger'
import {debounce} from '../../../../../lib/tools'
import {updatedAtDate} from '../../../../../lib/dateTools'

import './searchFormContainer.scss'
import {lookUpSearchDictionary} from './searchDictionary'


const SearchFormContainer = props => {
  const {className, responseSearch, itemSearch, contactSearch, cobranding} = props
  const formRef = useRef()
  const searchRef = useRef()

  const [responseResults, setResponseResults] = useState(List())
  const [itemResults, setItemResults] = useState(List())
  const [contactResults, setContactResults] = useState(List())
  const [textValue, setTextValue] = useState('')

  const logEvent = useCallback((eventName, payload = {}) => Logger.log({name: eventName, payload}), [])
  const searchNormalizedText = (searchObject, text) => {
    const reducer = (collection, value) =>
      collection.concat(fromJS(searchObject.search(value)))
    let resultMap = Map()

    return lookUpSearchDictionary(text)
      .reduce(reducer, List())
      .filterNot(result => {
        const isDuplicate = resultMap.has(result.get('id'))
        resultMap = resultMap.set(result.get('id'), true)
        return isDuplicate
      })
  }
  const searchText = text => {
    setResponseResults(searchNormalizedText(responseSearch, text))
    setItemResults(searchNormalizedText(itemSearch, text))
    setContactResults(searchNormalizedText(contactSearch, text))
  }
  const loggerWithDebounce = useCallback(debounce(logEvent, 5000), [])

  const latestUpdatedAt = data => (data.isEmpty() ? '' : updatedAtDate(data, 'X'))

  const searchWithDebounce = useCallback(debounce(searchText, 500), [
    latestUpdatedAt(props.responses),
    latestUpdatedAt(props.newContacts),
    props.eligibleItemsForSearch.size
  ])

  const onFocus = useCallback(() => logEvent('clicked_search_bar'), [])

  const onTextChange = useCallback(event => {
    const text = event.target.value || ''
    setTextValue(text)
    searchWithDebounce(text.trim())
    loggerWithDebounce('clicked_submit_search')
  })

  const resetSearch = useCallback(() => {
    setTextValue('')
    setResponseResults(List())
    setItemResults(List())
    setContactResults(List())
  })

  useEffect(() => {
    const handleClickOutside = event => {
      if (searchRef.current && !searchRef.current.contains(event.target))
        resetSearch()
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [searchRef])

  const isWhiteLabelFirmCobranding = cobranding.enabled && cobranding.style === 'white_label'

  return (
    <div
      className={classnames('search-form-container forms-playground', className, {
        cobranding: isWhiteLabelFirmCobranding
      })}
      ref={searchRef}>
      <div className='input-container core'>
        <input
          ref={formRef}
          placeholder={
            className ? 'Search' : 'Search for sections and data in your Everplan'
          }
          onFocus={onFocus}
          onChange={onTextChange}
          value={textValue}
        />
      </div>
      <SearchIcon showResults={!!textValue} resetSearch={resetSearch} />
      <SearchResultsContainer
        categories={props.categories}
        contactResults={contactResults}
        currentUserFirstLetter={props.currentUserFirstLetter}
        isHousehold={props.isHousehold}
        itemResults={itemResults}
        items={props.items}
        keyFieldMappings={props.keyFieldMappings}
        kits={props.kits}
        listMappings={props.listMappings}
        logEvent={logEvent}
        newContacts={props.newContacts}
        ownersDictionary={props.ownersDictionary}
        primitivesTopKitElementsMap={props.primitivesTopKitElementsMap}
        responseResults={responseResults}
        responses={props.responses}
        textValue={textValue}
      />
    </div>
  )
}

SearchFormContainer.propTypes = {
  categories: PropTypes.instanceOf(List),
  cobranding: PropTypes.shape({
    enabled: PropTypes.bool,
    style: PropTypes.string
  }),
  className: PropTypes.string,
  contactSearch: PropTypes.object,
  currentUserFirstLetter: PropTypes.string,
  isHousehold: PropTypes.bool,
  items: PropTypes.instanceOf(List),
  eligibleItemsForSearch: PropTypes.instanceOf(List),
  itemSearch: PropTypes.object,
  keyFieldMappings: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  newContacts: PropTypes.instanceOf(List),
  ownersDictionary: PropTypes.instanceOf(Map),
  primitivesTopKitElementsMap: PropTypes.instanceOf(Map),
  responses: PropTypes.instanceOf(List),
  responseSearch: PropTypes.object
}

export default SearchFormContainer
