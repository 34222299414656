import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {Text} from '../../../blocks/Texts'


const ResponseOwnership = ({
  responseOwnership,
  ownersDictionary
}) => {
  if (responseOwnership.isEmpty()) {
    return null
  } else {
    const ownerNames = responseOwnership
      .map(ownerId =>
        ownersDictionary.getIn([ownerId.toString(10), 'first-name'])
      ).sort()
      .join(' and ')
    return <Text className='response-ownership'>{ownerNames}</Text>
  }
}

ResponseOwnership.propTypes = {
  responseOwnership: PropTypes.instanceOf(List),
  currentUserOwnership: PropTypes.instanceOf(Map),
  ownersDictionary: PropTypes.instanceOf(Map)
}


export default ResponseOwnership
