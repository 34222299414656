import {List, Map} from 'immutable'
import {useMemo, memo} from 'react'
import PropTypes from 'prop-types'

import SearchFormContainer from './SearchFormContainer'

import {responsesWithItemResponse} from '../../../../../lib/plan_data/itemResponsesHelper'
import {itemsWithElementAndCategoryNames} from '../../../../../lib/plan_data/item'
import searchConfig from './searchConfig'


export const Search = props => {
  const itemsElementAndCategoryNames = useMemo(
    () => itemsWithElementAndCategoryNames({items: props.eligibleItemsForSearch, kits: props.kits, listMappings: props.listMappings, categoryDictionary: props.categoryDictionary}),
    [props.eligibleItemsForSearch.size]
  )

  const responsesElementAndItem = useMemo(() => responsesWithItemResponse({itemResponses: props.itemResponses, elementViewMap: props.elementViewMap}), [props.itemResponses])

  const responseSearch = searchConfig({
    indices: [['value', 'data'], 'display-name', ['itemResponse', 'header']],
    documents: responsesElementAndItem.toJS()
  })

  const itemSearch = searchConfig({
    indices: ['header', 'elementNamesAndListMappingValues', 'categoryName'],
    documents: itemsElementAndCategoryNames.toJS()
  })

  const contactSearch = searchConfig({
    indices: ['first-name', 'last-name', 'company-name', 'emails', 'phone-numbers'],
    documents: props.newContacts.toJS()
  })

  return (
    <SearchFormContainer
      {...props}
      responseSearch={responseSearch}
      itemSearch={itemSearch}
      contactSearch={contactSearch}
      responses={responsesElementAndItem}
    />
  )
}

Search.propTypes = {
  elementViewMap: PropTypes.instanceOf(Map),
  items: PropTypes.instanceOf(List),
  newContacts: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  categoryDictionary: PropTypes.instanceOf(Map),
  itemResponses: PropTypes.instanceOf(List),
  eligibleItemsForSearch: PropTypes.instanceOf(List)
}

const areEqual = (prevProps, nextProps) => (
  prevProps.eligibleItemsForSearch.equals(nextProps.eligibleItemsForSearch) &&
  prevProps.itemResponses.equals(nextProps.itemResponses) &&
  prevProps.newContacts.equals(nextProps.newContacts)
)

export default memo(Search, areEqual)
