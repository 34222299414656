/**
* @class Loader
*
* @deprecated This should never be used in any new code. Instead use `NewLoader`, which uses an SVG
*   instead of a GIF so it doesn't 'cover' the background color of the page while it is shown.
*/
import spinner from '../pro/assets/images/page_loader.gif'

import './spinner.scss'

const LegacyLoader = ({children, loading}) => (
  <div>
    {
      loading ?
        (
          <div className='page-loader'>
            <img alt='Loading...' src={spinner} />
          </div>
        ) :
        children
    }
  </div>
)

export default LegacyLoader
