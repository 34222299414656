import classnames from 'classnames'
import PropTypes from 'prop-types'
import digitalWorldIcon from '../../../../../shared_assets/category_icons/svg/digital-black.svg'
import familyAndLovedOnesIcon from '../../../../../shared_assets/category_icons/svg/family-black.svg'
import financialIcon from '../../../../../shared_assets/category_icons/svg/money-black.svg'
import legalIcon from '../../../../../shared_assets/category_icons/svg/documents-black.svg'
import medicalIcon from '../../../../../shared_assets/category_icons/svg/medical-black.svg'
import oldAgeIcon from '../../../../../shared_assets/category_icons/svg/time-black.svg'
import personalInfoAndIdsIcon from '../../../../../shared_assets/category_icons/svg/ids-black.svg'
import propertyIcon from '../../../../../shared_assets/category_icons/svg/home-black.svg'
import afterImGone from '../../../../../shared_assets/category_icons/svg/security-black.svg'

import './categoryHeaderIcon.scss'

const CATEGORY_ICONS = {
  'personal info and ids': personalInfoAndIdsIcon,
  digital: digitalWorldIcon,
  'family and loved ones': familyAndLovedOnesIcon,
  financial: financialIcon,
  legal: legalIcon,
  health: medicalIcon,
  aging: oldAgeIcon,
  'home and property': propertyIcon,
  "after i'm gone": afterImGone
}

// Need to change the `prop` `categoryName` to lower-case as we manipluate the URL slug for categories from the `/corp_max/Category.react.js` component. --KTW
const CategoryHeaderIcon = ({categoryName, className}) => <img className={classnames('category-header-icon', className)} src={CATEGORY_ICONS[categoryName.toLowerCase()]} alt={categoryName} />

CategoryHeaderIcon.propTypes = {
  categoryName: PropTypes.string
}

export default CategoryHeaderIcon
