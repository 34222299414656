import classnames from 'classnames'
import PropTypes from 'prop-types'

import {hasWhiteLabelCobranding} from '../../../lib/cobrandingTools'
import HelpCenterLink from './user_menu/HelpCenterLink'
import SecurityInfoLink from './user_menu/SecurityInfoLink'
import Logo from '../../../shared_components/Logo'
import UserMenu from './user_menu/UserMenu'
import SearchContainer from './corp_max/search/SearchContainer'

import storeWrapper from '../../../shared_components/storeWrapper'

import './headerBar.scss'


export const HeaderBar = props => (
  <header className={classnames('header-bar', props.displayBanner)}>
    {!hasWhiteLabelCobranding(props.config.data.cobranding) && <a href='#/cm/dashboard'><Logo /></a>}
    <div className='header-bar-nav flex-container'>
      <SearchContainer cobranding={props.config.data.cobranding} />
      <SecurityInfoLink />
      <HelpCenterLink />
      <UserMenu />
    </div>
  </header>
)

HeaderBar.defaultProps = {
  config: {
    data: {
      cobranding: {}
    }
  }
}

HeaderBar.propTypes = {
  config: PropTypes.shape({
    data: PropTypes.shape({
      cobranding: PropTypes.shape({
        enabled: PropTypes.bool
      })
    })
  }),
  displayBanner: PropTypes.string,
  isDeputyOnly: PropTypes.bool
}

export default storeWrapper(HeaderBar, [{name: 'config'}])
