import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import DownArrowIcon from './DownArrowIcon'
import MenuButton from './MenuButton'
import UserDropdown from './UserDropdown'


import currentUserWrapper from '../../../../shared_components/currentUserWrapper'
import {DropDownItem} from '../../../../shared_components/header/DropDownItem'

import './userMenu.scss'


export class UserMenu extends DropDownItem {
  constructor(props) {
    super(props)
    this.state = {open: false}
  }
  render() {
    return (
      <div className={classnames('user-menu', this.props.className)} onClick={this.toggleDropdown}>
        <MenuButton currentUser={this.props.currentUser} />
        {
          this.state.open &&
          <UserDropdown
            ariaHidden={!this.state.open}
            currentUser={this.props.currentUser}
            toggleDropdown={this.toggleDropdown}
          />
        }
        <span className='current-user-name'>{this.props.currentUser.first_name}</span>
        <DownArrowIcon />
      </div>
    )
  }
}


UserMenu.propTypes = {currentUser: PropTypes.object}

export default currentUserWrapper(UserMenu)
