import {List, Map, fromJS} from 'immutable'

import {compareCaseInsensitiveStrings} from './textTools'
import {humanizedFullNames} from './nameTools'


export const contactIsNotCurrentUser = ({contact, currentUser}) => {
  const currentUserEmails = currentUser.get('emails').map(email => email.get('email-address'))

  return contact.get('emails', List()).filter(email => currentUserEmails.includes(email)).isEmpty()
}

/** Helper method to either display the contact name, company name or default string
 *
 * @param {Map} contactData
 * @return {String} of last, first name or company
 */
export const contactNameOrCompany = contactData => {
  const contact = contactData.filterNot(value => !value)

  const contactName = humanizedFullNames(List([contact]))
  if (contactName)
    return contactName
  else
    return contactData.get('company-name', '')
}


/** Helper method to get all item headers where a contact is being used.
 *
 * @param {String} contactId
 * @param {List} itemResponses
 * @return {List} of item headers where a contact is being used.
 */
export const contactItems = ({contactId, itemResponses}) => (
  itemResponses.filter(item => item.get('responses')
    .some(response => response.get('element-type') === 'CONTACT' && response.getIn(['value', 'data', 'id']) === contactId))
    .map(item => item.get('header'))
)

/** Helper method to either display the contact name, company name or default string
 *
 * @param {Map} contactData
 * @return {String} of last, first name, company or default string depending on what information is provided
 */
export const contactLabel = contactData => contactNameOrCompany(contactData) || 'Unnamed Contact'

/** Perform a case-insensitive alphabetical sort on a `List` of `contacts` based on the `contactLabel` of each.
 *
 * @param {List} contacts The `List` of `contacts` to be sorted alphabetically.
 *
 * @return {List} an alphabetically-sorted `List` of the given `contacts`. Note that alphabetically-equivalent
 *.   `contactLabel` values (e.g., `yuri orlov` and `Yuri Orlov`) will remain in the same order in which they
 *    appear in the original `List`.
 */
export const sortContactsByCaseInsensitiveLabel = contacts => (
  contacts.sort(
    (contactOne, contactTwo) => compareCaseInsensitiveStrings(contactLabel(contactOne), contactLabel(contactTwo))
  )
)

export const contactGenderDisplayString = gender => {
  switch (gender) {
    case 'f':
      return 'Female/Woman'
    case 'm':
      return 'Male/Man'
    case 'non-binary':
      return 'Non-Binary'
    case 'something else':
      return 'Something Else'
    default:
      return ''
  }
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return
  
  const regex = /(\d{3})(\d{3})(\d{4})/
  const match = phoneNumber.match(regex)

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  } else {
    return phoneNumber
  }
}

// Gender options for contact form
export const GENDER_OPTIONS = fromJS([
  {label: 'Select gender', value: ''},
  {label: 'Female/Woman', value: 'Female/Woman', id: 'f'},
  {label: 'Male/Man', value: 'Male/Man', id: 'm'},
  {label: 'Non-Binary', value: 'Non-Binary', id: 'non-binary'},
  {label: 'Something Else', value: 'Something Else', id: 'something else'}
])

/** Helper method that returns a list of addresses or null
 *
 * @param {List} contactData
 * @return {List} of addresses or null
 */
export const getContactAddresses = contactData => {
  const isContactDataAnEmptyList = List.isList(contactData) && contactData.every(
    map => Map.isMap(map) && map.isEmpty()
  )

  return isContactDataAnEmptyList ? null : contactData
}
