// TODO:
// Need to create a SVG Icon component to handle multiple icons --KW

const HelpIcon = () => (
  <svg className='help-icon' version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
    <title>EP-HELP</title>
    <path d='M21.178 8.896c1.094 1.235 1.613 2.957 1.402 4.73-0.397 3.328-2.835 4.512-4.998 4.512-0.090 0-0.186 0-0.186 0.006v0.838c0 0.768-0.621 1.389-1.389 1.389s-1.389-0.621-1.389-1.389v-1.114c0-1.139 0.474-2.502 2.957-2.502 1.338 0 2.054-0.698 2.221-2.074 0.058-0.467 0.096-1.651-0.71-2.56-0.659-0.742-1.728-1.12-3.174-1.12-3.136 0-3.226 2.029-3.226 2.259 0 0.768-0.621 1.389-1.389 1.389s-1.389-0.621-1.389-1.389c0-1.402 1.075-5.030 5.997-5.030 2.848 0 4.448 1.114 5.274 2.054zM15.923 21.549c-0.454 0-0.902 0.186-1.229 0.512-0.32 0.32-0.506 0.768-0.506 1.222s0.186 0.902 0.506 1.229c0.326 0.32 0.768 0.512 1.229 0.512 0.454 0 0.902-0.186 1.229-0.512 0.32-0.32 0.506-0.768 0.506-1.229 0-0.454-0.186-0.902-0.506-1.222-0.326-0.326-0.774-0.512-1.229-0.512zM31.949 16c0 8.787-7.149 15.936-15.936 15.936s-15.936-7.149-15.936-15.936 7.149-15.936 15.936-15.936 15.936 7.149 15.936 15.936zM29.178 16c0-7.258-5.907-13.165-13.165-13.165s-13.165 5.901-13.165 13.165 5.907 13.165 13.165 13.165 13.165-5.907 13.165-13.165z' />
  </svg>
)

export default HelpIcon
