import React from 'react'
import {If} from '../deprecated/IfFlag'

export default class PoweredByHorizontal extends React.Component {
  render() {
    const foregroundColor = this.props.foreground_color || '#515151'

    return (
      <If flag={!!this.props.enabled}>
        <svg id={this.props.id} data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 23' className={this.props.className} x='0px' y='0px' width={this.props.width} height='30px'>
          <title>Powered by Everplans</title>
          <g>
            <path d='M70.9,17.8h0.7a1.8,1.8,0,0,0,1.8-1.2l3-7.2h-1l-2,4.9-2-4.9h-1l2.5,6.1-0.4.9a0.9,0.9,0,0,1-.9.6H71v0.8Zm-7.2-2.3V14.5a2.5,2.5,0,0,0,2,1c1.6,0,2.7-1.2,2.7-3.1s-1.1-3.2-2.7-3.2a2.5,2.5,0,0,0-2,1.1V7.2H62.7v8.3h0.9Zm0-1.6V11.1a2.2,2.2,0,0,1,1.8-1,2,2,0,0,1,1.9,2.3,2,2,0,0,1-1.9,2.3A2.2,2.2,0,0,1,63.6,13.8Zm-11.5.9a2,2,0,0,1-1.9-2.3c0-1.3.7-2.3,1.9-2.3a2.2,2.2,0,0,1,1.8,1v2.7A2.2,2.2,0,0,1,52.2,14.7Zm2.7,0.7V7.2H54v3.2a2.5,2.5,0,0,0-2-1.1c-1.6,0-2.7,1.2-2.7,3.2s1.1,3.1,2.7,3.1a2.5,2.5,0,0,0,2-1v0.9h0.9Zm-9.1-3.4H41.9a2,2,0,0,1,2-2A1.9,1.9,0,0,1,45.8,12.1ZM44,15.6a3.3,3.3,0,0,0,2.4-.9L45.9,14a2.6,2.6,0,0,1-1.8.8,2.1,2.1,0,0,1-2.2-2.1h4.9V12.5a2.9,2.9,0,0,0-2.9-3.2,3,3,0,0,0-3,3.1A3,3,0,0,0,44,15.6Zm-7.2-.1V11.2a2.2,2.2,0,0,1,1.6-1h0.4v-1a2.5,2.5,0,0,0-2,1.1v-1H35.8v6h0.9ZM32,12.1H28.1a2,2,0,0,1,2-2A1.9,1.9,0,0,1,32,12.1Zm-1.9,3.5a3.3,3.3,0,0,0,2.4-.9L32.1,14a2.6,2.6,0,0,1-1.8.8,2.1,2.1,0,0,1-2.2-2.1h4.9V12.5A2.9,2.9,0,0,0,30,9.3a3,3,0,0,0-3,3.1A3,3,0,0,0,30.1,15.6Zm-7.1-.1,1.9-6H24l-1.5,4.8L21,9.4H20.2l-1.6,4.8L17.1,9.4h-1l1.9,6H19l1.6-4.8,1.6,4.8h0.9Zm-12-.7a2.1,2.1,0,0,1-2-2.3,2.1,2.1,0,0,1,2-2.3,2.1,2.1,0,0,1,2,2.3A2.1,2.1,0,0,1,11.1,14.7Zm0,0.8a3,3,0,0,0,3-3.2,3,3,0,1,0-5.9,0A3,3,0,0,0,11.1,15.6ZM3.2,11.2H1V8.1H3.2A1.6,1.6,0,1,1,3.2,11.2ZM1,15.4V12.1H3.3A2.4,2.4,0,0,0,5.9,9.6,2.4,2.4,0,0,0,3.3,7.2H0v8.3H1Z' fill={foregroundColor} fillRule='evenodd' />
            <g>
              <path d='M115.7,12.1h-8.1a5.5,5.5,0,0,0,.9,3.6,3.7,3.7,0,0,0,3.2,1.6,4.6,4.6,0,0,0,3.5-2l0.8,0.6a5.6,5.6,0,0,1-4.7,2.5,5.8,5.8,0,0,1-4.3-1.7,6,6,0,0,1-1.7-4.4,5.9,5.9,0,0,1,1.6-4.2,5.3,5.3,0,0,1,4.1-1.7,4.5,4.5,0,0,1,3.7,1.6,5.8,5.8,0,0,1,1.2,4M107.7,11h5.6a3.9,3.9,0,0,0-.5-2.4,2.3,2.3,0,0,0-2.1-1.1,2.6,2.6,0,0,0-2.2,1.1,4.4,4.4,0,0,0-.8,2.4' fill={foregroundColor} />
              <path d='M121.8,15.6l2-4.6a8.9,8.9,0,0,0,.8-2.3,0.8,0.8,0,0,0-.7-0.7,0.7,0.7,0,0,1-.7-0.6V6.9h4.5V7.3a0.4,0.4,0,0,1-.3.5l-0.7.2a3.3,3.3,0,0,0-1,1.4l-3.9,8.8h-1.3l-4-9.1a2.9,2.9,0,0,0-.9-1.2l-0.6-.2a0.4,0.4,0,0,1-.2-0.5V6.9h5.4V7.3a0.7,0.7,0,0,1-.7.6,0.7,0.7,0,0,0-.7.6,3.5,3.5,0,0,0,.3.9Z' fill={foregroundColor} />
              <path d='M137.2,12.1H129a5.5,5.5,0,0,0,.9,3.6,3.7,3.7,0,0,0,3.2,1.6,4.6,4.6,0,0,0,3.5-2l0.8,0.6a5.6,5.6,0,0,1-4.7,2.5,5.8,5.8,0,0,1-4.3-1.7,6,6,0,0,1-1.7-4.4,5.9,5.9,0,0,1,1.6-4.2,5.3,5.3,0,0,1,4.1-1.7,4.5,4.5,0,0,1,3.7,1.6,5.8,5.8,0,0,1,1.2,4M129.1,11h5.6a3.9,3.9,0,0,0-.5-2.4,2.3,2.3,0,0,0-2.1-1.1,2.6,2.6,0,0,0-2.2,1.1,4.4,4.4,0,0,0-.8,2.4' fill={foregroundColor} />
              <path d='M141,6.8v3.6a6.9,6.9,0,0,1,1.1-2.4,2.7,2.7,0,0,1,2.2-1.4,1.7,1.7,0,0,1,1.1.4,1.2,1.2,0,0,1,.4,1,1.1,1.1,0,0,1-.4.9,1.4,1.4,0,0,1-.9.3l-0.7-.2-0.5-.2q-0.8,0-1.5,2A9.4,9.4,0,0,0,141,14v2.2a1,1,0,0,0,.4,1h0.7a0.4,0.4,0,0,1,.4.5v0.4h-5V17.7a0.4,0.4,0,0,1,.3-0.5h0.7a1,1,0,0,0,.4-0.9V8.9a1,1,0,0,0-.4-0.9h-0.7a0.4,0.4,0,0,1-.3-0.5V6.9H141Z' fill={foregroundColor} />
              <path d='M149.6,17.6v3.4a1,1,0,0,0,.4.9h0.7a0.5,0.5,0,0,1,.4.5v0.4h-4.9V22.5a0.4,0.4,0,0,1,.3-0.5l0.6-.2a1,1,0,0,0,.3-0.9V8.9a0.9,0.9,0,0,0-.6-1,0.6,0.6,0,0,1-.6-0.5V6.9h3.3V7.9A5.3,5.3,0,0,1,153,6.5a5,5,0,0,1,3.9,1.7,6.2,6.2,0,0,1,1.4,4.2,6.3,6.3,0,0,1-1.5,4.3,4.9,4.9,0,0,1-4.1,1.8,6,6,0,0,1-3.2-.9m0-9v8a3.6,3.6,0,0,0,2.5.9,3,3,0,0,0,2.8-1.6,7,7,0,0,0,.7-3.4,6.3,6.3,0,0,0-.8-3.3,3.1,3.1,0,0,0-2.8-1.5,3.6,3.6,0,0,0-2.5.9' fill={foregroundColor} />
              <path d='M162.6,0.1V16.1a1.1,1.1,0,0,0,.3.9l0.6,0.2a0.5,0.5,0,0,1,.3.5v0.4h-4.7V17.7a0.5,0.5,0,0,1,.3-0.5l0.6-.2a1,1,0,0,0,.3-0.9V2.1a1,1,0,0,0-.3-0.9l-0.6-.2a0.4,0.4,0,0,1-.3-0.5V0.1h3.5Z' fill={foregroundColor} />
              <path d='M171.2,11q0-3.6-2.3-3.6a0.9,0.9,0,0,0-1,1,1.2,1.2,0,0,1-1.4,1.3,1.3,1.3,0,0,1-.9-0.3,1.1,1.1,0,0,1-.4-0.8A1.8,1.8,0,0,1,166.6,7a5.7,5.7,0,0,1,2.2-.4q3,0,4.1,2a5.1,5.1,0,0,1,.6,2.5v3.7q0,2.5,1,2.5l0.6-.3h0.1l0.2,0.4a1,1,0,0,1-.6.7,2.3,2.3,0,0,1-1.2.4,2.2,2.2,0,0,1-2.2-1.6,4.6,4.6,0,0,1-3.4,1.6,4.4,4.4,0,0,1-2.7-.8,2.9,2.9,0,0,1-1.2-2.4q0-3.5,7-4.2m0.1,3.1V11.9a7.4,7.4,0,0,0-3,.8,2.8,2.8,0,0,0-1.6,2.5,2.3,2.3,0,0,0,.5,1.5,1.8,1.8,0,0,0,1.4.6,2.5,2.5,0,0,0,1.6-.6,3.2,3.2,0,0,0,1-2.6' fill={foregroundColor} />
              <path d='M176.2,7.4V6.9h3.3V8a5.3,5.3,0,0,1,3.6-1.4q3.7,0,3.7,3.2v6.4a1,1,0,0,0,.3.9l0.6,0.2a0.4,0.4,0,0,1,.3.5v0.4h-4.8V17.7a0.4,0.4,0,0,1,.3-0.5l0.6-.2a1,1,0,0,0,.3-0.9V10.4a3.8,3.8,0,0,0-.4-1.9,2.2,2.2,0,0,0-2-.8,3.3,3.3,0,0,0-1.5.4,2.7,2.7,0,0,0-1.2,1v7.1a1,1,0,0,0,.3.9l0.6,0.2a0.4,0.4,0,0,1,.3.5v0.4h-4.7V17.7a0.6,0.6,0,0,1,.6-0.6,0.9,0.9,0,0,0,.6-1V8.9A1,1,0,0,0,177,8l-0.5-.2a0.4,0.4,0,0,1-.3-0.5' fill={foregroundColor} />
              <path d='M192.4,18.5a7.6,7.6,0,0,1-2.7-.5q-1.7-.6-1.7-1.8a1.1,1.1,0,0,1,.4-0.9,1.4,1.4,0,0,1,1-.4,1.7,1.7,0,0,1,.9.3,0.9,0.9,0,0,1,.4.8v0.6q0,0.8,1.6.8a3.4,3.4,0,0,0,1.8-.4,1.6,1.6,0,0,0,.9-1.5q0-1.2-1.7-1.9l-3.1-1.2a2.8,2.8,0,0,1-1.7-2.6,3,3,0,0,1,1.6-2.6,5.7,5.7,0,0,1,3.1-.8,5.6,5.6,0,0,1,2.2.4,1.7,1.7,0,0,1,1.3,1.6,1,1,0,0,1-.4.8,1.5,1.5,0,0,1-.9.3l-0.9-.3a0.9,0.9,0,0,1-.4-0.8V8.1q0-.6-1.2-0.6a2.5,2.5,0,0,0-1.4.4,1.4,1.4,0,0,0-.7,1.2q0,1.1,1.7,1.8l3.2,1.2a2.9,2.9,0,0,1,1.7,2.6,3.2,3.2,0,0,1-1.6,2.8,5.7,5.7,0,0,1-3.2.9' fill={foregroundColor} />
              <path d='M100.9,12.3a8.6,8.6,0,1,1-8.6-8.6,8.6,8.6,0,0,1,8.6,8.6m-11.7,0a1.9,1.9,0,1,0-1.9,1.9,1.9,1.9,0,0,0,1.9-1.9m4.9,0a1.9,1.9,0,1,0-1.9,1.9,1.9,1.9,0,0,0,1.9-1.9m4.9,0a1.9,1.9,0,1,0-1.9,1.9,1.9,1.9,0,0,0,1.9-1.9' fill={foregroundColor} />
              <g>
                <path d='M197.2,6.8V5.3h-0.5V5H198V5.3h-0.5V6.8h-0.4Z' fill={foregroundColor} />
                <path d='M198.3,6.8V5h0.5l0.3,1.2L199.5,5H200V6.8h-0.3V5.4l-0.4,1.4H199l-0.4-1.4V6.8h-0.3Z' fill={foregroundColor} />
              </g>
            </g>
          </g>
        </svg>
      </If>
    )
  }
}

PoweredByHorizontal.defaultProps = {
  id: 'ep-logo-footer',
  className: 'powered-by ep-logo-footer'
}
