import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import {useMemo, memo} from 'react'
import PropTypes from 'prop-types'

import Search from './Search'

import {currentUserConfig, userOwnerOwnership} from '../../../../../lib/userTools'
import {primitivesTopKitElementsDictionary} from '../../../../../lib/plan_data/item'
import {viewElementsMap, defaultView} from '../../../../../lib/viewTools'
import {useEverplanData} from '../../../../../lib/hooks'


export const SearchContainer = props => {
  if (props.userConfig.isEmpty())
    return null

  const {elements, items, kits, listMappings, views} = props
  const {itemResponses, newContacts} = useEverplanData(props.userConfig.get('everplan-id'))

  const currentUserFirstLetter = useMemo(() => userOwnerOwnership({userConfig: props.userConfig, ownerships: props.owners}).get('first-name', '').charAt(0), [])
  const ownersDictionary = useMemo(() => Map(props.owners.map(owner => [owner.get('id'), owner])), [])

  const elementViewMap = useMemo(() => viewElementsMap({elements, views, view: defaultView(views)}), [])
  const categoryDictionary = useMemo(() => Map(props.categories.map(category => [category.get('id'), category])), [])
  const primitivesTopKitElementsMap = useMemo(() => primitivesTopKitElementsDictionary({items, kits, listMappings}), [])

  return (
    <Search
      {...props}
      isHousehold={props.userConfig.get('household')}
      ownersDictionary={ownersDictionary}
      eligibleItemsForSearch={props.items}
      itemResponses={itemResponses}
      elementViewMap={elementViewMap}
      categoryDictionary={categoryDictionary}
      newContacts={newContacts}
      currentUserFirstLetter={currentUserFirstLetter}
      primitivesTopKitElementsMap={primitivesTopKitElementsMap}
    />
  )
}

SearchContainer.propTypes = {
  categories: PropTypes.instanceOf(List),
  elements: PropTypes.instanceOf(Map),
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  owners: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map),
  views: PropTypes.instanceOf(List)
}

const mapStateToProps = state => ({
  categories: state.categories,
  elements: state.elements,
  items: state.items,
  keyFieldMappings: state.keyFieldMappings,
  kits: state.kits,
  listMappings: state.listMappings,
  userConfig: currentUserConfig(state.api),
  owners: state.owners,
  views: state.views
})

const areEqual = (prevProps, nextProps) => (
  prevProps.userConfig.equals(nextProps.userConfig) &&
  prevProps.owners.equals(nextProps.owners)
)

export default connect(mapStateToProps)(memo(SearchContainer, areEqual))
