import PropTypes from 'prop-types'

import {Text} from '../../blocks/Texts'

import './emptyResults.scss'

const EmptyResults = ({textValue}) => (
  <div className='empty-results'>
    <Text className='bold-text'>No results for "{textValue}"</Text>
    <Text>
      Search for sections, keywords, data or contacts in your Everplan
    </Text>
  </div>
)


EmptyResults.propTypes = {
  textValue: PropTypes.string
}


export default EmptyResults
