import classnames from 'classnames'

import HelpIcon from './HelpIcon'
import * as Links from '../../../../shared_components/Links'

import './helpCenterLink.scss'

const HelpCenterLink = props => (
  <Links.Help className={classnames('help-center-link flex-child', props.className)}>
    <HelpIcon />
    <span className='help-link-text'>Help</span>
  </Links.Help>
)

export default HelpCenterLink
