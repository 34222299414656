import {List, Map} from 'immutable'

import {findResourceById} from './userData'
import {camelizeImmutableData} from '../graphQLTools'
import {dateWithMaskFromForm} from '../dateTools'

export const isCompoundItemResponse = itemResponse => itemResponse.get('top-kits').size > 1

export const firstTopKitParentElementId = itemResponse => itemResponse.getIn(['top-kits', 0, 'parent-element-id'])

export const responseGroupsMap = responseGroups => responseGroups.reduce((collection, responseGroup) =>
  collection.set(responseGroup.get('id'), responseGroup), Map()
)

export const itemResponsesWithResponseGroupMap = itemResponses => itemResponses
  .filterNot(itemResponse => itemResponse.get('response-groups').isEmpty())
  .map(itemResponse => itemResponse.set('responseGroupMap', responseGroupsMap(itemResponse.get('response-groups'))))
  .sortBy(itemResponse => itemResponse.get('sort-order'))


export const topKitElementIdsWithResponse = responseGroup => (
  responseGroup
    .get('top-elements-with-responses', List())
    .reduce((collection, topElement) => (
      topElement.get('responses-grouped-by-second-group-id').isEmpty() ? collection : collection.push(topElement.get('element-id'))
    ), List())
)

export const firstTopKitElementIdWithResponse = responseGroup => topKitElementIdsWithResponse(responseGroup).first()

export const itemResponseGroupOwners = responseGroup =>
  responseGroup.get('owners').map(owner => owner.get('first-name')).join(' and ').trim()

// Returns itemResponses with `responseGroupMap` under `items` with the category they belong to
// This will only return categories that have ItemResponses that have response groups.
export const categoriesWithItemResponseGroupMaps = ({categories, itemResponses}) => {
  const itemResponseGroupMaps = itemResponsesWithResponseGroupMap(itemResponses)
  if (itemResponseGroupMaps.isEmpty())
    return List()

  const reducer = (collection, category) => {
    const categoryItemResponses = itemResponseGroupMaps
      .filter(item => item.get('category-id') === category.get('id'))

    if (categoryItemResponses.isEmpty())
      return collection
    else
      return collection.push(category.set('items', categoryItemResponses))
  }

  return (
    categories
      .reduce(reducer, List())
      .sortBy(category => category.get('sort-order'))
  )
}

export const allResponseGroupCount = itemResponses => (
  itemResponses.reduce((count, itemResponse) => (itemResponse.get('response-groups').isEmpty() ? count : count + itemResponse.get('response-groups').size), 0)
)

export const isEditableItemResponse = ({ownEverplan, firmOwnership, itemResponse, context}) => (
  ownEverplan || ((context === 'pro_client_dashboard') &&
   firmOwnership.get('edit-item-ids', List()).includes(itemResponse.get('item-id')))
)

export const secondResponseGroupsByElementId = ({responseGroup, elementId}) => {
  const topElementWithResponses = responseGroup
    .get('top-elements-with-responses', List())
    .find(topElementWithResponseData => topElementWithResponseData.get('element-id') === elementId) || List()

  return topElementWithResponses.get('responses-grouped-by-second-group-id', List())
}

// The response group ID in the url could be the first or the second and so need to account for that
export const itemResponseGroupByGroupId = ({itemResponse, groupId}) => {
  const firstResponse = itemResponse.get('responses').find(response => response.get('group-id').includes(groupId))

  return findResourceById({resourceList: itemResponse.get('response-groups'), id: firstResponse?.getIn(['group-id', 0])})
}

export const itemResponseTopKitListMappingId = itemResponse => (
  itemResponse.get('kit-elements-map')
    .find(kitElementMap => kitElementMap.get('id') === itemResponse.getIn(['top-kits', 0, 'id']))
    .getIn(['kit-elements', 0, 'list-mapping'])
)

export const itemResponseKitElementsById = ({itemResponse, id}) => findResourceById({resourceList: itemResponse.get('kit-elements-map'), id}).get('kit-elements')

// // Updating Item Form Elements (based on responses, conditional values, etc.)

export const defaultElementMapping = element => {
  if (element.get('select-type') === 'Select' && element.get('ordinality') !== 'SELECT-WITHOUT-DEFAULT')
    return element.getIn(['mappings', 0])
}

export const elementMapping = ({element, value = null}) => (
  value && element.get('mappings') ? element.get('mappings').find(mappingData => mappingData.get('value') === value) : defaultElementMapping(element)
)

export const formElementValue = ({formResponses, element, defaultConditionalValue = null}) => {
  const formElement = formResponses.find(response => response.get('element-id') === element.get('id'))
  return formElement?.get('value') || defaultConditionalValue
}

export const elementWithConditionalKitElementsByValue = ({itemResponse, element, formResponses, defaultConditionalValue}) => {
  const elements = List([element])
  const mapping = elementMapping({element, value: formElementValue({formResponses, element, defaultConditionalValue})})

  if (mapping?.get('conditional-kit-id'))
    return elements.concat(itemResponseKitElementsById({itemResponse, id: mapping.get('conditional-kit-id')}))

  return elements
}

export const isValidResponseMapValue = responseValue => {
  if (responseValue)
    return responseValue.delete('typename').some(value => !!value)
}

// // Submitting Responses

export const formatSubmittedResponses = responses => responses.reduce((collection, responseData) => {
  const response = responseData.hasIn(['value', 'typename']) ? responseData.deleteIn(['value', 'typename']) : responseData
  const responseValue = response.get('value')

  const sharedAttributes = Map({
    id: response.get('id'),
    elementId: response.get('element-id'),
    responseType: response.get('type')
  })

  switch (response.get('type')) {
    case 'ADDRESS': {
      return collection.push(
        sharedAttributes.set('addressValue', isValidResponseMapValue(responseValue) ? camelizeImmutableData(responseValue) : null)
      )
    }
    case 'FILE': {
      return collection.push(
        sharedAttributes.set('fileArrayValue',
          responseValue &&
          responseValue.map(fileValue => ({
            id: fileValue.get('id'),
            name: fileValue.get('name'),
            deleted: fileValue.get('deleted')
          }))
        )
      )
    }
    case 'DATE': {
      return collection.push(
        sharedAttributes.set('dateValue', isValidResponseMapValue(responseValue) ? dateWithMaskFromForm(responseValue) : null)
      )
    }
    default: {
      const valueType = ['SELECT-MULTI', 'SELECT-MULTI-WITH-OTHER'].includes(response.get('ordinality')) ? 'stringArrayValue' : 'stringValue'
      return collection.push(sharedAttributes.set(valueType, responseValue))
    }
  }
}, List())

export const buildItemResponseMutationVariables = ({itemResponse, ownershipIds = null, groupId, responses = List()}) => ({
  everplanId: itemResponse.get('everplan-id'),
  input: {
    itemId: itemResponse.get('item-id'),
    ownershipIds,
    groupId,
    responses: formatSubmittedResponses(responses)
  }
})

// /////

// Used for the nextBestActions
export const itemResponseGroupsForUser = ({itemResponse, userConfig}) => {
  const responseGroups = itemResponse.get('response-groups')

  if (itemResponse.get('is-household'))
    return responseGroups.filter(responseGroup => responseGroup.get('owners').map(owner => owner.get('ownable-id')).includes(parseInt(userConfig.get('id'), 10)))
  else
    return responseGroups
}

// Used for the search functionality
export const responsesWithItemResponse = ({
  itemResponses,
  elementViewMap
}) => itemResponses.reduce((collection, itemResponse) => {
  const responseGroups = itemResponse.get('response-groups')

  if (responseGroups.isEmpty())
    return collection

  const responses = itemResponse.get('responses')

  return collection.concat(responses.reduce((responsesWithKeyField, response) => {
    const currentResponseGroup = responseGroups.find(responseGroup => responseGroup.get('id') === response.getIn(['group-id', 0]))

    if (currentResponseGroup) {
      return responsesWithKeyField.push(response
        .set('itemResponse', itemResponse)
        .set('display-name', elementViewMap.getIn([response.getIn(['element-id']), 'display-name']))
        .set('key-field', currentResponseGroup.get('key-field'))
      )
    }

    return responsesWithKeyField
  }, List()))
}, List())

/**
 *
 * @param {List} responses
 * @returns {List}
 */
export const removeEmptyResponses = responses => responses.filter(response => response.getIn(['value', 'data']))
