import $ from 'jquery'


const hexToRGBA = (hex, alpha = 1, brightness = 100) => {
  var hexValuesArray = hex.replace('#', '').split('')

  if (hexValuesArray.length === 3)
    hexValuesArray = $.map(hexValuesArray, value => [value, value])

  const hexString = `0x${hexValuesArray.join('')}`

  return `rgba(${[
    lightenColor((hexString >> 16), brightness),
    lightenColor((hexString >> 8), brightness),
    lightenColor((hexString & 255), brightness),
    alpha
  ].join(', ')})`
}

const lightenColor = (color, brightness) => Math.ceil((color & 255) * (brightness / 100))

export default hexToRGBA
