import DropdownRowBase from './DropdownRowBase'

import featureFlags from '../../../../lib/FeatureFlags'
import {railsUrl} from '../../../../lib/urlTools'

import logoutIcon from '../../../assets/ep-log-out.svg'


const SignOutRow = () => (
  <DropdownRowBase path={featureFlags.isEnabled('feedback_modal') ? 'logout-feedback' : null}>
    <a href={featureFlags.isEnabled('feedback_modal') ? null : railsUrl('sign_out')}>
      <img src={logoutIcon} className='dropdown-icon' alt='logout-icon' />
      Log Out
    </a>
  </DropdownRowBase>
)

export default SignOutRow
