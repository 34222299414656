import PropTypes from 'prop-types'
import React from 'react'

import DropdownRowBase from './DropdownRowBase'
import * as Links from '../../../../shared_components/Links'
import helpIcon from '../../../assets/ep-help.svg'


const HelpRow = props => {
  const HelpLink = props.currentUser.firm_user ? Links.ProHelp : Links.Help

  return (
    <DropdownRowBase>
      <HelpLink>
        <img src={helpIcon} className='dropdown-icon' alt='help-icon' />
        Help
      </HelpLink>
    </DropdownRowBase>
  )
}

HelpRow.propTypes = {
  currentUser: PropTypes.shape({
    firm_user: PropTypes.bool
  })
}

export default HelpRow
