import {Map, List} from 'immutable'

import {findResourceByAttributeId} from './plan_data/userData'
import {conditionalKitElementsForElementValue, isElementWithConditionalKits, allConditionalKitElementsForElement, elementListMappingValue} from './plan_data/item'

export const defaultView = views => views.find(view => view.get('name') === 'default-en')

export const defaultViewElementById = ({views, elementId}) => (
  findResourceByAttributeId({
    attribute: 'element-id',
    resourceList: defaultView(views).getIn(['data', 'elements']),
    id: elementId
  })
)

/**
 * Finds the view element in the view if available or from the default view that corresponds to the passed in element
 *
 * @param {List} views
 * @param {Map} view
 * @param {Map} element
 *
 * @returns {Map} of the view element
 */
export const viewElementById = ({views, view, elementId}) => {
  const viewElementData = view && view
    .getIn(['data', 'elements'])
    .concat(view.getIn(['data', 'conditional-elements'], List()))
    .find(elementData => elementData.get('element-id') === elementId)

  return viewElementData || defaultViewElementById({views, elementId})
}

/**
 * @param {List} views
 * @param {Map} view
 * @param {Map} element
 *
 * @returns {Map} of the placeholder and legend for the passed in element from the corresponding view element
 */
export const elementFieldsViewData = ({views, view, elementId}) => {
  const viewElementData = viewElementById({views, view, elementId})
  return Map({
    placeholder: viewElementData.get('placeholder'),
    legend: viewElementData.get('field-prompt'),
    'display-name': viewElementData.get('display-name'),
    'view-id': view ? view.get('id') : defaultView(views).get('id')
  })
}

export const kitElementsFieldViewData = ({kit, views, view}) => (
  kit.get('elements').map(element => element.merge(elementFieldsViewData({views, elementId: element.get('id'), view})))
)
/**
 * Finds the view elements in the view if available or from the default view that corresponds to each element in the list
 *
 * @param {List} views
 * @param {Map} view
 * @param {List} elements
 *
 * @returns {Map} element id keys with the corresponding view element as the value
 */
export const viewElementsMap = ({elements, views, view}) => (
  elements.reduce((viewElements, element) => viewElements.set(
    element.get('id'),
    viewElementById({views, view, elementId: element.get('id')})
  ), Map())
)

/**
 * @param {List} elements
 * @param {Map} view
 *
 * @returns {List} of sorted elements that correspond to each view element
 */
export const elementsPerViewByType = ({view, elements, type}) => {
  if (view.isEmpty() || !view.getIn(['data', type])) {
    return List()
  } else {
    return view
      .getIn(['data', type], '')
      .sortBy(viewElementData => viewElementData.get('sort-order', ''))
      .map(viewElementData => elements.get(viewElementData.get('element-id')))
  }
}


// /////////////////////////////////////////////////////////////////////////////
// Views Tool Manager Helpers

/**
 * Filters all elements from from data that have a value, a list-mapping, is not the selected element and is not part of
 * any previously selected elements
 *
 * @param {Map} element selected element for a view
 * @param {Map} formData
 * @param {List} selectedElements all selected elements for a view
 *
 * @returns {List} of valid elements that could be a dependent response element
*/
export const potentialDependentResponseElementsFromForm = ({element, selectedElements, formData}) => (
  formData.filter(form =>
    form.get('value') &&
    form.get('list-mapping') &&
    form.get('element-id') !== element.get('id') &&
    !selectedElements.some(selectedElement => selectedElement.get('id') === form.get('element-id'))
  )
)

/**
 * Constructs a response object with dependent element ids for a view and used to save any additional responses in the background if
 * a response is added for any element that is included in the list of dependent element ids.
 *
 * @param {Map} kits
 * @param {Map} listMappings
 * @param {Map} element selected element for a view
 * @param {Map} formData
 * @param {List} elements current form elements
 * @param {List} selectedElements all selected elements for a view
 *
 * @returns {List} of the dependent response and element ids for a given element
*/
export const dependentResponseElementFromForm = ({element, elements, formData, kits, listMappings, selectedElements}) => {
  const reducer = (collection, formElement) => {
    const dependentElements = conditionalKitElementsForElementValue({element: formElement, listMappings, kits}) || elements
    return collection
      .set(
        formElement.get('element-id'),
        Map({
          id: formElement.get('id'),
          'dependent-element-ids': dependentElements.map(dependentElement => dependentElement.get('id')),
          value: formElement.get('value'),
          'element-id': formElement.get('element-id')
        })
      )
  }

  return potentialDependentResponseElementsFromForm({element, formData, selectedElements})
    .reduce(reducer, Map())
    .filter(dependentElement => dependentElement
      .get('dependent-element-ids')
      .includes(element.get('id'))
    )
}

/**
 * Formats the dependent view elements of a view to be used when updating a view in the Views Tool
 *
 * @param {Map} view
 *
 * @returns {Map} of a view's dependent response elements with the element-id as key and the dependent response element as the value
*/
export const formatDependentResponseElements = view => {
  const dependentResponseElements = view.getIn(['data', 'dependent-response-elements'])

  if (dependentResponseElements) {
    return view
      .getIn(['data', 'dependent-response-elements'])
      .reduce((collection, dependentResponseElement) =>
        collection.set(dependentResponseElement.get('element-id'), dependentResponseElement), Map())
  } else {
    return Map()
  }
}

/**
 * @param {Map} element
 * @param {List} selectedElements all selected elements for a view
 *
 * @returns {Map} of the selectedElement that has the element's id in its list of conditional element ids
*/
export const selectedElementForConditionalElement = ({selectedElements, element}) => (
  selectedElements.find(selectedElement => selectedElement.get('conditional-element-ids', List()).includes(element.get('id')))
)


/**
 * Constructs the label for an element to include the name of the parent element that an element belongs to if it is a conditional
 *
 * @param {Map} element
 * @param {List} selectedElements all selected elements for a view
 *
 * @returns {string} of the label for an element
*/
export const elementLabel = ({element, selectedElements}) => {
  if (element.get('list-mapping-value'))
    return `${selectedElementForConditionalElement({selectedElements, element}).get('name')} (${element.get('list-mapping-value')}) Conditional`
  else
    return element.get('name')
}


/**
 * Finds the elements that correspond to the elements in a view and adds a flat list of conditional element ids to any element
 * that has conditional kits
 *
 * @param {Map} kits
 * @param {Map} listMappings
 * @param {Map} elements all elements from the global state
 * @param {Map} view
 *
 * @example
 * List([
 *  {
 *    id: 'c4b70a1e-202e-4491-84e6-341db8355b68',
 *    name: 'How did you create your will',
 *    'conditional-element-ids': [
 *      '75b2e3e4-6858-447f-98ca-32978e6f9ec1',
 *      '0c93a0cc-1cb0-47c1-872a-57281ef9e8af',
 *      '9e7d94e7-abe4-4e24-875d-eeef84b9b7a2',
 *      'd63f8cff-14aa-4e0e-9dcc-0c3c24e78c64'
 *    ]
 *  }
 * ])
 * @returns {List} elements to be used when updating selected elements of a view
*/
export const formatSelectedElementsFromView = ({view, elements, listMappings, kits}) => {
  const selectedElements = elementsPerViewByType({view, elements, type: 'elements'})

  const reducer = (collection, element) => {
    if (isElementWithConditionalKits({element, listMappings})) {
      return collection.push(element.set(
        'conditional-element-ids',
        allConditionalKitElementsForElement({kits, listMappings, element}).map(elementData => elementData.get('id'))
      ))
    } else {
      return collection.push(element)
    }
  }

  return selectedElements.reduce(reducer, List())
}

/**
 * Finds the elements that correspond to the conditional elements in a view and adds key of list-mapping-value
 * with the value to be used when updating a view in the Views Tool
 *
 * @param {Map} kits
 * @param {Map} listMappings
 * @param {Map} elements all elements from the global state
 * @param {Map} view
 *
 * @example
 * List([
 *  {id: '75b2e3e4-6858-447f-98ca-32978e6f9ec1', name: 'Attorney Contact Info', `list-mapping-value`: 'Attorney' }
 *  {id: '9e7d94e7-abe4-4e24-875d-eeef84b9b7a2', name: 'Name of Service', `list-mapping-value`: 'Online' }
 * ])
 * @returns {List} conditional elements to be used when updating selected conditional elements of a view
*/
export const formatSelectedConditionalElementsFromView = ({view, elements, listMappings, kits}) => (
  elementsPerViewByType({view, elements, type: 'conditional-elements'})
    .map(element => elementListMappingValue({element, kits, listMappings}))
)


/**
 * @param {Map} elements
 * @param {Map} view
 *
 * @returns {List} of elements that are included in a view
*/
export const filterElementsByView = ({elements, view}) => {
  const viewElementIds = view
    .getIn(['data', 'elements'])
    .concat(view.getIn(['data', 'conditional-elements'], List()))
    .map(viewElementData => viewElementData.get('element-id'))

  return elements
    .filter(element => viewElementIds.includes(element.get('id')))
}

const dependentResponsesWithValue = ({responses, dependentResponseElement}) => {
  const dependentElementIds = dependentResponseElement.get('dependent-element-ids')

  return responses.filter(response => (
    dependentElementIds.includes(response.get('element-id')) &&
    response.get('element-id') !== dependentResponseElement.get('element-id') &&
    response.get('value')
  ))
}
/**
 * Returns any dependent responses as part of the responses a group of responses might have, by first checking if the view has any
 * dependent response elements and that the responses are not empty, then checks if any `element-id` from the responses is part of
 * the `dependent-element-ids` attached to each `dependent-response-element`.
 *
 * @param {List} responses
 * @param {Map} view
 * @param {array} groupId
 *
 * @returns {List} responses including any dependent responses that need to be saved as well
*/
export const dependentResponsesFromView = ({responses, view, groupId}) => {
  const dependentResponseElements = view.getIn(['data', 'dependent-response-elements'], List())

  if (!responses.isEmpty() && !dependentResponseElements.isEmpty()) {
    const reducer = (collection, dependentResponseElement) => {
      const existingDependentResponseWithValue = responses.find(
        response => response.get('value') && response.get('element-id') === dependentResponseElement.get('element-id')
      )
      const dependentElementIdsWithResponseValue = dependentResponsesWithValue({responses, dependentResponseElement})

      if (!dependentElementIdsWithResponseValue.isEmpty() && !existingDependentResponseWithValue) {
        return collection
          .filterNot(response => dependentResponseElement.get('element-id') === response.get('element-id'))
          .push(dependentResponseElement.delete('dependent-element-ids').set('group-id', groupId))
      } else if (dependentElementIdsWithResponseValue.isEmpty() && existingDependentResponseWithValue) {
        return collection
          .filterNot(response => dependentResponseElement.get('element-id') === response.get('element-id'))
          .push(existingDependentResponseWithValue.set('value', ''))
      } else {
        return collection
      }
    }
    return dependentResponseElements.reduce(reducer, responses)
  } else {
    return responses
  }
}

/**
 * Finds the view that corresponds to a given view name
 *
 * @param {string} viewName
 * @param {List} views
 *
 * @returns {Map} of view found
 */
export const findViewByName = ({viewName, views}) =>
  findResourceByAttributeId({
    resourceList: views,
    id: viewName,
    attribute: 'name'
  })

/**
 * Filters all views that corresponds to the List of names passed in
 *
 * @param {List} viewNames
 * @param {List} views
 *
 * @returns {List} of views found
 */
export const filterViewsByName = ({viewNames, views}) =>
  viewNames.map(viewName => findViewByName({viewName, views}))

/**
 * Gets all the ids of elements including dependent-response-elements and
 * conditional-elements that belongs to a view
 *
 * @param {Map} view
 *
 * @returns {List} of ids of elements that belongs to a view.
 */
export const allViewElementIds = view => (
  view
    .getIn(['data', 'elements'])
    .concat(view.getIn(['data', 'dependent-response-elements'], List()))
    .concat(view.getIn(['data', 'conditional-elements'], List()))
    .map(element => element.get('element-id'))
)
