import DropdownRowBase from './DropdownRowBase'
import {MyProDashboard} from '../../../../shared_components/Links'
import proSwitchIcon from '../../../assets/ep-switch.svg'


const ProDashboardRow = () => (
  <DropdownRowBase>
    <MyProDashboard>
      <img src={proSwitchIcon} className='dropdown-icon' alt='pro-switch-icon' />
      Pro Account
    </MyProDashboard>
  </DropdownRowBase>
)

export default ProDashboardRow
