import PropTypes from 'prop-types'
import {Map, List} from 'immutable'
import {useCallback} from 'react'

import {newItemPath} from '../../../../../../lib/urlTools'
import {firstElementIdForItem} from '../../../../../../lib/plan_data/item'
import {categorySlug} from '../../../../../../lib/plan_data/category'

import routerUtils from '../../../../../../lib/routerUtils'
import CategoryHeaderIcon from '../../shared/CategoryHeaderIcon'
import {Text} from '../../../blocks/Texts'

const Item = ({item, categories, kits, logEvent}) => {
  const itemCategory = categories.find(
    category => category.get('id') === item.get('category-id')
  )

  const url = () => newItemPath({
    itemSlug: item.get('slug'),
    slug: categorySlug(itemCategory),
    elementId: firstElementIdForItem({item, kits}),
    path: '/cm/category/'
  })

  const onClick = useCallback(() => {
    logEvent('clicked_search_result', {type: 'item', item: item.get('header'), wildcard: item.get('header')})
    routerUtils.push(url())
  }, [])

  return (
    <div className='flex-container result border-bottom' onClick={onClick}>
      <CategoryHeaderIcon categoryName={itemCategory.get('name')} />
      <div className='result-content'>
        <Text className='bold-text'>{item.get('header')}</Text>
        <Text className='blue-text'>Add New</Text>
      </div>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.instanceOf(Map),
  categories: PropTypes.instanceOf(List),
  logEvent: PropTypes.func,
  kits: PropTypes.instanceOf(Map)
}

export default Item
