import {gql} from '@apollo/client'

import {PERMISSION_FRAGMENT, DEPUTY_FRAGMENT, OWNABLE_FRAGMENT} from '../fragments/deputy'
import {OWNER_OWNERSHIP_FRAGMENT} from '../fragments/userData'

export const SPECIFIC_ITEMS_DEPUTIES = gql`
  ${PERMISSION_FRAGMENT}
  ${OWNABLE_FRAGMENT}
  query specificItemsDeputies {
    specificItemsDeputies {
      id
      kitId
      itemId
      deputies {
        id
        ownershipId
        ownable {...OwnableFragment}
        permission {...PermissionFragment}
      }
    }
  }
`
export const DEPUTIES = gql`
  ${DEPUTY_FRAGMENT}
  query deputies($everplanId: ID!) {
    deputies(everplanId: $everplanId) {...DeputyFragment}
  }
`

export const DEPUTIZERS = gql`
  ${PERMISSION_FRAGMENT}
  ${OWNER_OWNERSHIP_FRAGMENT}
  query deputizers($userId: ID!) {
    deputizers(userId: $userId) {
      id
      anyDeputyHasDeferredReadItems
      customInviteText
      deathReporter
      deathVerificationPeriod
      deputyRelationship
      everplanId
      everplanName
      ownableId
      ownableType
      permissions {
        read {...PermissionFragment}
        deferred {...PermissionFragment}
      }
      everplanOwner {
        id
        isHousehold
        owners {
          ...OwnerOwnershipFragment
          gender
          email
          pronoun {
            id
            legacyGender
            modifyingPossessive
            object
            pluralConjugation
            reflexive
            standalonePossessive
            subject
          }
        }
      }
    }
  }
`
