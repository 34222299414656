import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {List, Map} from 'immutable'

import ResponseResults from './ResponseResults'
import ItemResults from './ItemResults'
import ContactResults from './ContactResults'
import EmptyResults from './EmptyResults'

import {handleModalWrapperPosition} from '../../../../../lib/scrollTools'

import './searchResultsContainer.scss'


const SearchResultsContainer = props => {
  const {textValue, itemResults, responseResults, contactResults, ...otherProps} = props
  const wrapperPosition = handleModalWrapperPosition()

  const lockScroll = () => {
    wrapperPosition.lockWrapper()
    wrapperPosition.lockPage()
  }

  const unlockScroll = () => {
    wrapperPosition.unlockPage()
    wrapperPosition.unlockWrapper()
  }

  useEffect(() => {
    textValue ? lockScroll() : unlockScroll()
    return () => unlockScroll()
  }, [textValue])

  if (textValue) {
    return (
      <div className='search-results-container'>
        {responseResults.isEmpty() &&
          itemResults.isEmpty() &&
          contactResults.isEmpty() && <EmptyResults textValue={textValue} />}
        <ResponseResults
          {...otherProps}
          responseResults={responseResults}
          textValue={textValue}
        />
        <ContactResults
          contactResults={contactResults}
          logEvent={props.logEvent}
        />
        <ItemResults
          categories={props.categories}
          itemResults={itemResults}
          kits={props.kits}
          logEvent={props.logEvent}
        />
      </div>
    )
  } else {
    return null
  }
}

SearchResultsContainer.propTypes = {
  itemResults: PropTypes.instanceOf(List),
  contactResults: PropTypes.instanceOf(List),
  responseResults: PropTypes.instanceOf(List),
  textValue: PropTypes.string,
  categories: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  logEvent: PropTypes.func
}


export default SearchResultsContainer
