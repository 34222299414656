import {gql} from '@apollo/client'

export const OWNABLE_FRAGMENT = gql`
  fragment OwnableFragment on Ownable {
    id
    email
    firmUser
    firstName
    lastName
    status
  }
`

export const PERMISSION_FRAGMENT = gql`
  fragment PermissionFragment on Permission {
    id
    firstGroupId
    itemId
    kitId
    level
    ownershipId
    permissionType
  }
`

export const DEPUTY_FRAGMENT = gql`
  ${OWNABLE_FRAGMENT}
  ${PERMISSION_FRAGMENT}
  fragment DeputyFragment on Deputy {
    id
    deathReporter
    deathVerificationPeriod
    deputyRelationship
    everplanId
    ownableType
    ownable {...OwnableFragment}
    viewedItems {
      id
      createdAt
      groupId
      itemId
    }
    permissions {
      read {...PermissionFragment}
      deferred {...PermissionFragment}
    }
  }
`
