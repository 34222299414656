import React from 'react'

import Forms from './forms'
import ResponsiveModal from './ResponsiveModal'

import './modalForm.scss'


// Shared functionality that exposes Form.Forms methods to parent components:
export class ModalFormBase extends React.Component {
  constructor(props) {
    super(props)

    this.errors = this.errors.bind(this)
    this.formData = this.formData.bind(this)
    this.isValid = this.isValid.bind(this)
    this.validate = this.validate.bind(this)
  }
  errors() { return this.refs.form.errors() }
  formData() { return this.refs.form.formData() }
  isValid() { return this.refs.form.isValid() }
  validate() { return this.refs.form.validate() }
}


// All modal content that is the same for standard modal and pro modal:
export class ModalFormBody extends ModalFormBase {
  render() {
    return (
      <span>
        {this.props.title && <div className='form-title'><h2>{this.props.title}</h2></div>}
        {this.props.instructions && <div className='form-instructions'>{this.props.instructions}</div>}
        <Forms.Form errors={this.props.errors} ref='form'>
          {this.props.formBody ? this.props.formBody : this.props.children}
        </Forms.Form>
      </span>
    )
  }
}


export default class ModalForm extends ModalFormBase {
  render() {
    return (
      <ResponsiveModal modalType='modal-form-content' {...this.props}>
        <ModalFormBody {...this.props} ref='form' />
      </ResponsiveModal>
    )
  }
}
