import classnames from 'classnames'

import Modals from '../../../../shared_components/core//modals/Modals'
import UnorderedList from '../../../../shared_components/UnorderedList'
import {Privacy, Security} from '../../../../shared_components/Links'
import SecurityIcon from './SecurityIcon'

import lockIcon from '../../../../shared_assets/lock-icon@2x.png'
import './securityInfoLink.scss'

const SecurityInfoLink = props => (
  <Modals.LinkPopUpModalLarge
    className = 'security-info-link flex-child'
    launcher={
      <a
        className = {classnames('security-info-launcher', props.className)}>
        <SecurityIcon />
        <span className='security-info-text'>Security</span>
      </a>
    }>
    <div className='header-section'>
      <img
        src={lockIcon}
        alt='padlock with a check mark with plain background'
      />
      <h2>Everplans is serious about security.</h2>
    </div>
    <p>
      We engineered Everplans with the guiding principle of keeping your
      personal information private, secure, and protected against any
      type of attacker.
    </p>
    <UnorderedList
      children = {
        [
          'All information in your Everplan is encrypted using the most modern standards.',
          'Your personal information belongs to you alone -- only people you choose to share your information with can decrypt your data.',
          'We are governed and audited under the same security and privacy frameworks -- HIPAA and SOC2 -- used by major financial institutions and healthcare providers.'
        ]
      }
    />
    <div className='links-section'>
      <Security target='_blank'>Read more about our security practices</Security>
      <Privacy target='_blank'>Read our Privacy Policy</Privacy>
    </div>
  </Modals.LinkPopUpModalLarge>
)

export default SecurityInfoLink
