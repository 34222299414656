import React from 'react'

import { capitalize } from '../lib/tools'
import Container from '../lib/Container'
import storePrototype from './StorePrototype'


const storeWrapper = (Component, storeConfigs) => {
  storeConfigs.forEach(storeConfig => {
    if (storeConfig.actionTypes && storeConfig.actionTypes.length) // If actionTypes is empty, assume the store is already registered
      Container.registerStore(storeConfig.name, storePrototype(storeConfig.actionTypes))
  })

  class StoreWrapper extends React.Component {
    constructor(props) {
      super(props)

      this.stores = storeConfigs.reduce(
        (stores, storeConfig) => {
          stores[storeConfig.name] = Container.getStore(storeConfig.name)
          return stores
        },
        {}
      )

      this.state = Object.keys(this.stores).reduce(
        (state, storeName) => ({
          ...state,
          ...{[storeName]: Container.getStore(storeName).getState()}
        }),
        {}
      )
    }
    componentDidMount() {
      Object.keys(this.stores).forEach(store => {
        let onChangeHandlerName = `handle${capitalize(store)}Change`
        this[onChangeHandlerName] = this.onChangeFactory(store)
        this.stores[store].addChangeListener(this[onChangeHandlerName])
      })
    }
    componentWillUnmount() { Object.keys(this.stores).forEach(store => this.stores[store].removeChangeListener(this[`handle${capitalize(store)}Change`])) }
    onChangeFactory(store) { return () => this.setState({[store]: this.stores[store].getState()}) }

    render() { return <Component {...this.props} {...this.state} /> }
  }

  return StoreWrapper
}

export default storeWrapper
