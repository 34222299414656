import DropdownRowBase from './DropdownRowBase'
import {Privacy, Security, Terms} from '../../../../shared_components/Links'

import './footerRow.scss'


const FooterRow = () => (
  <DropdownRowBase className='footer-row'>
    <Privacy target='_blank' />
    <Security target='_blank' />
    <Terms target='_blank'>Terms</Terms>
  </DropdownRowBase>
)

export default FooterRow
