import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Map, List} from 'immutable'
import {useCallback} from 'react'
import Highlighter from 'react-highlight-words'

import ResponseOwnership from './ResponseOwnership'

import {firstGroupId} from '../../../../../../lib/responsesHelper'
import {categorySlugByItem} from '../../../../../../lib/plan_data/category'
import {viewItemPath} from '../../../../../../lib/urlTools'
import routerUtils from '../../../../../../lib/routerUtils'
import {Text} from '../../../blocks/Texts'
import {contactLabel} from '../../../../../../lib/contactTools'
import {dateResponseValue} from '../../../../../../lib/dateTools'
import {lookUpSearchDictionary} from '../searchDictionary'

import './response.scss'

const Response = ({
  categories,
  currentUserFirstLetter,
  isHousehold,
  ownersDictionary,
  responseResultGroup,
  logEvent,
  textValue,
  primitivesTopKitElementsMap
}) => {
  // Get the first response with a value.
  const response = responseResultGroup.find(resp => resp.get('value'), null, Map())
  // Bail if there is no response with a value in the response group.
  // Some legacy contact responses do not have values on prod. - Kay
  if (response.isEmpty()) return null

  const itemResponse = response.get('itemResponse')
  const hasFileResponse = responseResultGroup.some(resp => resp.get('element-type') === 'FILE')

  const url = () => viewItemPath({
    elementId: primitivesTopKitElementsMap.getIn([
      response.get('element-id'),
      'id'
    ]),
    groupId: firstGroupId(responseResultGroup),
    itemSlug: itemResponse.get('slug'),
    slug: categorySlugByItem({item: itemResponse, categories}),
    path: '/cm/category/'
  })

  const onClick = useCallback(() => {
    logEvent('clicked_search_result', {
      type: 'response',
      item: itemResponse.get('header'),
      wildcard: itemResponse.get('header')
    })
    routerUtils.push(url())
  }, [])

  const responseView = () => {
    const responseValue = response.getIn(['value', 'data'])
    const elementType = response.get('element-type')

    if (elementType === 'CONTACT')
      return contactLabel(responseValue)
    else if (elementType === 'FILE')
      return responseValue.map(file => file.get('name')).join(', ')
    else if (elementType === 'ADDRESS')
      return responseValue.valueSeq().join(', ')
    else if (elementType === 'DATE')
      return dateResponseValue(responseValue)

    if (List.isList(responseValue)) return responseValue.join(', ')
    else return responseValue
  }

  const textArray = textValue ? lookUpSearchDictionary(textValue.trim()).toJS() : []

  return (
    <div
      className='flex-container result border-bottom response-result'
      onClick={onClick}>
      <div className='profile-circle'>{currentUserFirstLetter}</div>
      <div className='result-with-ownership'>
        <div className='result-content'>
          <Text className='bold-text'>
            <Highlighter
              searchWords={textArray}
              textToHighlight={itemResponse.get('header')}
              autoEscape={true}
            />
          </Text>
          <Text
            className={classnames('keyfield blue-text', {
              'has-file-response': hasFileResponse
            })}>
            {response.get('key-field')}
          </Text>
          <div className='flex-container'>
            <Text className='display-name'>
              <Highlighter
                highlightClassName='bold-text'
                searchWords={textArray}
                textToHighlight={response.get('display-name')}
                autoEscape={true}
              />
              :
            </Text>
            <Text className='response-value'>
              <Highlighter
                highlightClassName='bold-text'
                searchWords={textArray}
                textToHighlight={responseView()}
                autoEscape={true}
              />
            </Text>
          </div>
        </div>
        {isHousehold && (
          <ResponseOwnership
            responseOwnership={response.get('ownership-ids') || List()}
            ownersDictionary={ownersDictionary}
          />
        )}
      </div>
    </div>
  )
}

Response.propTypes = {
  categories: PropTypes.instanceOf(List),
  currentUserFirstLetter: PropTypes.string,
  isHousehold: PropTypes.bool,
  items: PropTypes.instanceOf(List),
  logEvent: PropTypes.func,
  ownersDictionary: PropTypes.instanceOf(Map),
  responseResultGroup: PropTypes.instanceOf(List),
  textValue: PropTypes.string,
  primitivesTopKitElementsMap: PropTypes.instanceOf(Map)
}

export default Response
