import PropTypes from 'prop-types'

import {cobrandingPrefixText} from '../../lib/cobrandingTools'

import './mobileHeaderEverplansBrandedCobranding.scss'


const MobileHeaderEverplansBrandedCobranding = ({cobranding}) => (
  <div className='mobile-header-everplans-branded-cobranding'>
    <div>{cobranding.cobranding_prefix}</div>
    <div>{cobranding.cobrandable.name}</div>
  </div>
)

MobileHeaderEverplansBrandedCobranding.defaultProps = {
  cobranding: {
    cobranding_prefix: '',
    name: ''
  }
}

MobileHeaderEverplansBrandedCobranding.propTypes = {
  cobranding: PropTypes.shape({
    cobranding_prefix: PropTypes.string,
    name: PropTypes.string
  })
}

export default MobileHeaderEverplansBrandedCobranding
