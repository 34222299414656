import * as JsSearch from 'js-search'

const searchConfig = ({ref = 'id', indices, documents}) => {
  const search = new JsSearch.Search(ref)
  // this index strategy is built for all substrings matches.
  search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()

  indices.forEach(index => search.addIndex(index))
  search.addDocuments(documents)
  return search
}

export default searchConfig
