import DropdownRowBase from './DropdownRowBase'
import settingsIcon from '../../../assets/ep-account-settings.svg'
import {accountSettingsPath} from '../../../../lib/urlTools'

const AccountSettingsRow = () => (
  <DropdownRowBase path={accountSettingsPath}>
    <a>
      <img src={settingsIcon} className='dropdown-icon' alt='settings-icon' />
      Settings
    </a>
  </DropdownRowBase>
)

export default AccountSettingsRow
